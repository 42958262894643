/********************************************************************** The Road to Valhalla! ************************************************************************
 *                                                                                                                                                                   *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌           *
 *  📌                                                                                                                                                  📌         *
 *  📌                 SOLANA LAUNCHPAD WITH JITO BUNDLING = INITIAL BUY FOR THE CLIENTS WITH SIMULATE, DISPERSE, ENABLE & BUY OPERATIONS               📌        *
 *  📌                                                                                                                                                  📌      *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌      *
 *                                                                                                                                                             *
 *  Project Type  : Solana Launchpad with Jito Bundling                                                                                                       *
 *   Project ID   : 2024-3                                                                                                                                   *
 *   Client Info  : Private                                                                                                                                 *
 *    Developer   : GloryDream, Rothschild, HappySmile (Nicknames)                                                                                         *
 *   Source Mode  : 100% Private                                                                                                                          *
 *   Description  : Solana Launchpad-Frontend .                                                                                                          *
 *  Writing Style : P0413-K0408-K1206                                                                                                                   *
 *                                                                                                                                                     *
 ********************************************************************** The Road to Valhalla! *********************************************************
 */
// import Samples node_modules
import { FaDatabase, FaEllipsisV, FaExclamationTriangle, FaQuestion, FaRegCopy, FaSave } from "react-icons/fa";
import { IoIosAddCircle, IoIosDownload } from "react-icons/io";
import { useContext, useState, useEffect } from "react";
import { Popover } from "@headlessui/react";
import { toast } from "react-toastify";
import BigNumber from "bignumber.js";
import axios from "axios";
import bs58 from "bs58";


// import  blockchain integration
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { ellipsisAddress, isValidAddress } from "../utils/methods";
import { getMint, getAccount, getAssociatedTokenAddress } from "@solana/spl-token";
import { Keypair, PublicKey } from "@solana/web3.js";

// import personal layouts and styles

import { AppContext } from "../App";

import HolderAmountDialog from "../components/Dialogs/HolderAmountDialog";
import NewWalletDialog from "../components/Dialogs/NewWalletDialog";
import ZombieDialog from "../components/Dialogs/ZombieDialog";

import MySidebar1 from "./MySidebar1";
import MySidebar2 from "./MySidebar2";

export default function BuySellBot({ className }) {

    const {
        currentVolume,
        setOpenLoading
    } = useContext(AppContext);

    // MBC - Select the volume.token.address
    const [targetChain, setTargetChain] = useState("solana");
    const [nativeCoin, setNativeCoin] = useState("SOL");

    const [tokenAddress, setTokenAddress] = useState(currentVolume.token ? currentVolume.token.address : "");
    const [cTokenAddress, setCTokenAddress] = useState(currentVolume.token ? currentVolume.token.address : "");
    const [showGraph, setShowGraph] = useState(true);
    const [showTransaction, setShowTransaction] = useState(false);
    const [showOrder, setShowOrder] = useState('market');


    const handleEnterPress = (e) => {
        if (e.key == 'Enter') {
            setCTokenAddress(e.target.value);
        }
    }

    const handleGraphChange = () => {
        if (!showGraph) {
            setShowGraph(true);
            setOpenLoading(true);
            setTimeout(() => {
                setOpenLoading(false);
            }, 3000);

        } else {
            setShowGraph(false);
            setOpenLoading(true);
            setTimeout(() => {
                setOpenLoading(false);
            }, 1000);
        }
        setShowGraph(!showGraph);
    }

    const handleTransactionChange = () => {
        if (!showTransaction) {
            setShowTransaction(true);
            if (showGraph) {
                setOpenLoading(true);
                setTimeout(() => {
                    setOpenLoading(false);
                }, 3000);
            }

        } else {
            setShowTransaction(false);
            if (showGraph) {
                setOpenLoading(true);
                setTimeout(() => {
                    setOpenLoading(false);
                }, 1000);
            }
        }
        setShowTransaction(!showTransaction);
    }

    const handleShowMarket = () => {
        setShowOrder('market');
    }

    const handleShowLimit = () => {
        setShowOrder('limit');
    }

    useEffect(() => {
        setOpenLoading(true);
        setTimeout(() => {
            setOpenLoading(false);
        }, 3000);
    }, [])

    return (
        <div className="flex flex-row">
            <MySidebar1 />
            <div className={`${className} w-full h-full m-8 gap-4`}>
                <div className="font-bold uppercase text-3xl text-white mb-4" >Buy / Sell Bot </div>
                <div className="flex flex-row text-white text-xl items-center my-4">
                    <div className="inline-block"> Token Address : </div>
                    <div className="inline-block flex flex-row items-center justify-center flex-grow ml-2">
                        <input
                            className="w-full outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent h-button"
                            placeholder="Enter Token Address"
                            value={tokenAddress}
                            onKeyDown={(e) => handleEnterPress(e)}
                            onChange={(e) => setTokenAddress(e.target.value)}
                        />
                    </div>
                    <button
                        className={`${showGraph ? 'bg-[rgba(0,255,255,0.5)]' : 'bg-[rgba(255,0,0,0.2)]'} ml-4 font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                        onClick={handleGraphChange}
                    >
                        {showGraph ? '🔗 Hide Graph' : '💎 Show Graph'}
                    </button>

                    {showGraph &&
                        <button
                            className={`${showTransaction ? 'bg-[rgba(0,255,255,0.5)]' : 'bg-[rgba(255,0,0,0.2)]'} ml-4 font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                            onClick={handleTransactionChange}
                        >
                            {showTransaction ? '🔗 Hide Transactions' : '💎 Show Transactions'}
                        </button>
                    }

                </div>

                {showGraph &&
                    <div className="flex">
                        <iframe className={`w-full ${showTransaction ? 'h-[700px]' : 'h-[500px]'} `}
                            src={`https://dexscreener.com/${targetChain}/${cTokenAddress}?embed=1&theme=dark&info=0${showTransaction ? '&trades=1' : '&trades=0'}`}
                        >
                        </iframe>
                    </div>
                }

                <div className="bg-[rgba(0,0,0,0.2)] p-4 my-4 p-8 rounded-[20px]">
                    <div clasName="flex flex-row my-16">
                        <button
                            className={`${showOrder == 'market' ? 'bg-[rgba(0,255,0,0.4)]' : 'bg-[rgba(0,255,0,0.1)]'} mr-4 font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                            onClick={handleShowMarket}
                        >

                            Market Orders
                        </button>
                        <button
                            className={`${showOrder == 'limit' ? 'bg-[rgba(0,255,0,0.4)]' : 'bg-[rgba(0,255,0,0.1)]'} mr-4 font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                            onClick={handleShowLimit}
                        >
                            Limit Orders
                        </button>
                    </div>

                    <div className="text-white my-4 w-full">
                        {showOrder == 'market' &&
                            // Market Order Page
                            <>
                                <div className="flex flex-row gap-8 w-full">
                                    <div className="text-xl flex flex-row items-center gap-4 w-[1/2] p-4 justify-between w-full bg-[rgba(0,0,0,0.3)] rounded-[10px]">
                                        <span> {nativeCoin} Price Now </span>
                                    </div>
                                    <div className="text-xl flex flex-row items-center gap-4 w-[1/2] p-4 justify-between w-full bg-[rgba(0,0,0,0.3)] rounded-[10px]">
                                        <span> Token Price Now </span>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-8 mt-4 w-full">
                                    <div className="flex flex-row items-center gap-4 w-[1/2] p-4 justify-between w-full bg-[rgba(0,0,0,0.3)] rounded-[10px]">
                                        <span> Buy Amount </span>
                                        <input
                                            className="flex-grow outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent h-button"
                                            placeholder="Enter Buy Amount "
                                        />
                                        <button
                                            className={`${true ? 'bg-[rgba(0,255,255,0.5)]' : 'bg-[rgba(255,0,0,0.2)]'} ml-4 font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                                        >
                                            Buy Now
                                        </button>
                                    </div>
                                    <div className="flex flex-row items-center gap-4 w-[1/2] p-4 justify-between w-full bg-[rgba(0,0,0,0.3)] rounded-[10px]">
                                        <span> Sell Amount </span>
                                        <input
                                            className="flex-grow outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent h-button"
                                            placeholder="Enter Buy Amount "
                                        />
                                        <button
                                            className={`${false ? 'bg-[rgba(0,255,255,0.5)]' : 'bg-[rgba(255,0,0,0.2)]'} ml-4 font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                                        >
                                            Sell Now
                                        </button>
                                    </div>
                                </div>
                            </>
                        }

                        {showOrder == 'limit' &&
                            // Limit Order Page
                            <>
                                <div className="flex flex-row gap-8 mt-4 w-full">
                                    <div className="flex flex-col items-center w-[1/2] p-4 justify-between w-full bg-[rgba(0,0,0,0.3)] rounded-[10px]">
                                        <div className="text-2xl mb-2"> Limit Order Operations</div>

                                        {/* Order Buy */}
                                        <div className="flex flex-row items-center p-4 justify-between w-full">
                                            <div className="flex-col flex items-start">
                                                <span className="mb-1 text-center"> Limit Price : </span>
                                                <input
                                                    className="w-[150px] outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent h-button"
                                                    placeholder="Enter Buy Amount "
                                                />
                                            </div>

                                            <div className="flex-col flex items-start">
                                                <span className="mb-1 text-center"> Buy Amount : </span>
                                                <input
                                                    className="w-[150px] outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent h-button"
                                                    placeholder="Enter Buy Amount "
                                                />
                                            </div>
                                            <div className="flex-col flex items-start justfiy-end">
                                                <span className="mb-1 text-center"> &nbsp; </span>
                                                <button
                                                    className={`w-[140px] ${true ? 'bg-[rgba(0,255,255,0.5)]' : 'bg-[rgba(255,0,0,0.2)]'} font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                                                >
                                                    Order Buy
                                                </button>
                                            </div>
                                        </div>

                                        {/* Order Sell */}
                                        <div className="flex flex-row items-center p-4 justify-between w-full">
                                            <div className="flex-col flex items-start">
                                                <span className="mb-1 text-center"> Limit Price : </span>
                                                <input
                                                    className="w-[150px] outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent h-button"
                                                    placeholder="Enter Limit Price "
                                                />
                                            </div>

                                            <div className="flex-col flex items-start">
                                                <span className="mb-1 text-center"> Sell Amount : </span>
                                                <input
                                                    className="w-[150px] outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent h-button"
                                                    placeholder="Enter Sell Amount "
                                                />
                                            </div>
                                            <div className="flex-col flex items-start justfiy-end">
                                                <span className="mb-1 text-center"> &nbsp; </span>
                                                <button
                                                    className={`w-[140px] ${false ? 'bg-[rgba(0,255,255,0.5)]' : 'bg-[rgba(255,0,0,0.2)]'} font-sans text-xs font-medium text-center text-white uppercase px-4 h-10 rounded-[4px] justify-center items-center gap-2.5 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed`}
                                                >
                                                    Order Sell
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col items-center gap-4 w-[1/2] p-4 bg-[rgba(0,0,0,0.3)] rounded-[10px] text-center">
                                        <div className="text-2xl"> Limit Order Table</div>
                                        <table class="w-full border-collapse m-auto">
                                            <thead class="bg-[rgba(255,0,255,0.1)] border text-white">
                                                <tr class="h-16">
                                                    <th class="border w-[20px] px-4">No</th>
                                                    <th class="border px-4">Limit</th>
                                                    <th class="border px-4">Amount</th>
                                                    <th class="border px-4">Type</th>
                                                    <th class="border px-4">...</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="h-12 hover:bg-gray-10">
                                                    <td class=" w-[20px] border px-4">1</td>
                                                    <td class="border px-4">1.5$</td>
                                                    <td class="border px-4">100$</td>
                                                    <td class="border px-4 bg-[rgba(0,255,0,0.1)]"> buy</td>
                                                    <td class="border px-4">Active</td>
                                                </tr>
                                                <tr class="h-12 hover:bg-gray-10">
                                                    <td class="w-[20px] border px-4">2</td>
                                                    <td class="border px-4">2.0$</td>
                                                    <td class="border px-4">100$</td>
                                                    <td class="border px-4 bg-[rgba(255,0,0,0.2)]">sell</td>
                                                    <td class="border px-4">Active</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </>
                        }
                    </div>

                </div>
            </div>
            <MySidebar2></MySidebar2>
        </div >
    );
}