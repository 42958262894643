/********************************************************************** The Road to Valhalla! ************************************************************************
 *                                                                                                                                                                   *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌           *
 *  📌                                                                                                                                                  📌         *
 *  📌                 SOLANA LAUNCHPAD WITH JITO BUNDLING = INITIAL BUY FOR THE CLIENTS WITH SIMULATE, DISPERSE, ENABLE & BUY OPERATIONS               📌        *
 *  📌                                                                                                                                                  📌      *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌      *
 *                                                                                                                                                             *
 *  Project Type  : Solana Launchpad with Jito Bundling                                                                                                       *
 *   Project ID   : 2024-3                                                                                                                                   *
 *   Client Info  : Private                                                                                                                                 *
 *    Developer   : GloryDream, Rothschild, HappySmile (Nicknames)                                                                                         *
 *   Source Mode  : 100% Private                                                                                                                          *
 *   Description  : Solana Launchpad-Frontend .                                                                                                          *
 *  Writing Style : P0413-K0408-K1206                                                                                                                   *
 *                                                                                                                                                     *
 ********************************************************************** The Road to Valhalla! *********************************************************
 */
// import Samples node_modules
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IoIosLock } from "react-icons/io";
import axios from "axios";

// import personal layouts and styles
import { AppContext } from "../App";
import RegisteredDialog from "../components/Dialogs/RegisteredDialog";
import TradingView from './TradingView';

export default function LoginPage({ }) {
    const [openRegistered, setOpenRegistered] = useState(false);

    const [loginName, setLoginName] = useState("");
    const [loginPass, setLoginPass] = useState("");

    const [signupName, setSignupName] = useState("");
    const [signupPass, setSignupPass] = useState("");

    const { SERVER_URL, setLoadingPrompt, setOpenLoading, setUser } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        setOpenLoading(true);
    }, []);

    const handleSubmitSignIn = async (e) => {
        e.preventDefault();

        const name = loginName;
        const password = loginPass;
        if (name === "") {
            toast.warn("Please input user name");
            return;
        }

        if (password === "") {
            toast.warn("Please input password");
            return;
        }

        setLoadingPrompt("Logging in...");
        setOpenLoading(true);
        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/user/login`,
                {
                    name,
                    password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (data.success) {
                localStorage.setItem("access-token", data.accessToken);
                setUser(data.user);
            }
            else
                toast.warn("Failed to register");
        }
        catch (err) {
            console.log(err);
            setOpenLoading(false);
            toast.warn("Failed to login");
        }
    };

    const handleSubmitSignUp = async (e) => {
        e.preventDefault();

        const name = signupName;
        const password = signupPass;
        if (name === "") {
            toast.warn("Please input user name");
            return;
        }

        if (password.length < 8) {
            toast.warn("Password should be longer than 8 characters");
            return;
        }

        setLoadingPrompt("Registering...");
        setOpenLoading(true);
        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/user/register`,
                {
                    name,
                    password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (data.success) {
                setOpenLoading(false);
                setOpenRegistered(true);
            }
            else
                toast.warn("Failed to register");
        }
        catch (err) {
            console.log(err);
            setOpenLoading(false);
            toast.warn("Failed to register");
        }
    };

    // Switch handlers for switching the tabs
    const switchers = [...document.querySelectorAll('.switcher')]

    switchers.forEach(item => {
        item.addEventListener('click', function () {
            switchers.forEach(item => item.parentElement.classList.remove('is-active'))
            this.parentElement.classList.add('is-active')
        })
    })

    return (
        <div className="wrapper">
            <RegisteredDialog isOpen={openRegistered}
                onOK={() => { setOpenRegistered(false); }} />

            <section class="forms-section">
                {/* <TradingView/> */}
                <h1 class="section-title">SOLANA LAUNCHPAD</h1>
                <div class="forms">
                    <div class="form-wrapper is-active">
                        <button type="button" class="switcher switcher-login">
                            Sign in
                            <span class="underline"></span>
                        </button>
                        <form class="form form-login" onSubmit={handleSubmitSignIn}>
                            <fieldset>
                                <legend>Please, enter your email and password for login.</legend>
                                <div class="input-block">
                                    <label for="login-email">E-mail</label>
                                    <input id="login-email" type="email" value={loginName} onChange={(e) => { setLoginName(e.target.value) }} required />
                                </div>
                                <div class="input-block">
                                    <label for="login-password">Password</label>
                                    <input
                                        id="login-password"
                                        type="password"
                                        value={loginPass}
                                        onChange={(e) => { setLoginPass(e.target.value) }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSubmitSignIn(e);
                                            }
                                        }}
                                        required
                                    />
                                </div>
                            </fieldset>
                            <button type="submit" style={{ background: 'purple', color: 'white' }}>Sign in</button>
                        </form>
                    </div>
                    <div class="form-wrapper" onSubmit={handleSubmitSignUp}>
                        <button type="button" class="switcher switcher-signup">
                            Sign Up
                            <span class="underline"></span>
                        </button>
                        <form class="form form-signup">
                            <fieldset>
                                <legend>Please, enter your email, password and password confirmation for sign up.</legend>
                                <div class="input-block">
                                    <label for="signup-email">E-mail</label>
                                    <input id="signup-email" type="email" value={signupName} onChange={(e) => { setSignupName(e.target.value) }} required />
                                </div>
                                <div class="input-block">
                                    <label for="signup-password">Password</label>
                                    <input id="signup-password" value={signupPass} type="password" onChange={(e) => { setSignupPass(e.target.value) }} required />
                                </div>
                            </fieldset>
                            <button type="submit" style={{ background: 'lightslategrey', color: 'white' }}>Sign Up</button>
                        </form>
                    </div>
                </div>
            </section>

        </div>
    );
}
