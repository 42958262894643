import { useContext, useEffect, useState } from "react";
import { generateAvatarURL } from "@cfx-kit/wallet-avatar";
import { useWallet } from "@solana/wallet-adapter-react";

import { AppContext } from "../App";
import ConnectWalletButton from "./ConnectWalletButton";
import MyAssetsButton from "./MyAssetsButton";
import AvatarDropDown from "../components/AvatarDropdown";
import { useNavigate, useLocation } from "react-router-dom";

export default function NavBar({ className, breadCrumb }) {
    const { user, logout } = useContext(AppContext);
    const { publicKey } = useWallet();
    // const [randomAvatar, setRandomAvatar] = useState(generateAvatarURL(new Date().getTime().toString()));
    const [randomAvatar, setRandomAvatar] = useState("/avatar.png");
    const navigate = useNavigate();

    const onViewProfile = () => {
        console.log("View Profile");
    };

    useEffect(() => {
        // setRandomAvatar(generateAvatarURL(publicKey?.toBase58() || new Date().getTime().toString()));
    }, [publicKey]);

    return (
        // <div className={`${className ? className : ""} font-sans flex-col lg:flex-row flex lg:justify-between lg:items-center lg:px-7 px-4 z-[50]`}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', padding: '50px', paddingBottom: '0px' }}>
            <img src="/assets/logo.png" className="w-24 h-12 logo_animate" onClick={() => {
                navigate("/dashboard");
            }} />

            <div style={{
                color: 'white',
                letterSpacing: '3px',
                fontSize: '28px',
                fontWeight: '600',
                width: '100%',
                marginLeft: '30px',
                display: 'flex',
                flexDirection: 'row'
            }}>
                {breadCrumb && breadCrumb.map((item, index) => {
                    return (
                        <>
                            <div className="bread-active" onClick = {(event) => {
                                if (event.target.textContent == "Home") {
                                    navigate("/dashboard");
                                } else if (event.target.textContent == "My Projects") {
                                    navigate("/myprojects");
                                } else if (event.target.textContent == "Token Menu") {
                                    navigate("/token-menu");
                                }
                                 else if (event.target.textContent == "Market Maker") {
                                    navigate("/market-maker");
                                }
                            }}>{item}</div>
                            {index < breadCrumb.length - 1 && (<>&nbsp;>&nbsp;</>)}
                        </>

                    );
                })}
            </div>

            <div className="flex items-center  gap-2 lg:justify-end lg:gap-5 min-w-[300px]">
                <MyAssetsButton />
                <ConnectWalletButton />
                <img src="/assets/common/logout.png" className="w-12 h-12 mr-2 brightness-50 hover:brightness-100 hover:cursor-pointer hover:scale-110 transition duration-2000"
                    alt="spinner" onClick={logout} />
                {/* <AvatarDropDown imageUrl={randomAvatar} name={user.name ? user.name : ""} address={publicKey?.toBase58()} onLogout={logout} onViewProfile={onViewProfile} /> */}
            </div>
        </div>
    );
}
