/********************************************************************** The Road to Valhalla! ************************************************************************
 *                                                                                                                                                                   *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌           *
 *  📌                                                                                                                                                  📌         *
 *  📌                 SOLANA LAUNCHPAD WITH JITO BUNDLING = INITIAL BUY FOR THE CLIENTS WITH SIMULATE, DISPERSE, ENABLE & BUY OPERATIONS               📌        *
 *  📌                                                                                                                                                  📌      *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌      *
 *                                                                                                                                                             *
 *  Project Type  : Solana Launchpad with Jito Bundling                                                                                                       *
 *   Project ID   : 2024-3                                                                                                                                   *
 *   Client Info  : Private                                                                                                                                 *
 *    Developer   : GloryDream, Rothschild, HappySmile (Nicknames)                                                                                         *
 *   Source Mode  : 100% Private                                                                                                                          *
 *   Description  : Solana Launchpad-Frontend .                                                                                                          *
 *  Writing Style : P0413-K0408-K1206                                                                                                                   *
 *                                                                                                                                                     *
 ********************************************************************** The Road to Valhalla! *********************************************************
 */
// import Samples node_modules
import { useContext, useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

// import personal layouts and styles

import MySidebar1 from "./MySidebar1";
import MySidebar2 from "./MySidebar2";

export default function MarketMaker({ className }) {

    const navigate = useNavigate();

    return (
        <div className="flex flex-row">
            <MySidebar1 />
            <div className="flex flex-col w-full h-full m-auto m-16 rounded-[24px]" >
                <p className="text-3xl text-center  text-white p-4"> Trading Bots for your token success</p>
                <div className="flex flex-row border border-gray-border market-maker-panel m-4 rounded-[24px] cursor-pointer"
                    onClick={() => {
                        navigate('/volume-bot');
                    }}>
                    <div className="flex flex-col justify-center items-center m-8 min-w-[200px]" >
                        <img src="/assets/projects/market_maker/volume_bot.jpg" className="btn_bot" />
                        <h1 style={{ color: 'white', fontSize: '24px', marginTop: '16px' }}>Volume Bot</h1>
                    </div>
                    <p className="rounded-[20px] panel-text text-xl m-8 p-4 text-justify">
                        &nbsp;Crypto trading volume is important as it helps you identify a coin's possible direction. Many consider that crypto with the highest trading volume makes for the healthiest investment. Generally, high trading volume translates to higher stability and liquidity.
                    </p>
                </div>

                <div className="flex flex-row border border-gray-border market-maker-panel m-4 rounded-[24px] cursor-pointer"
                    onClick={() => {
                        navigate('/holder-bot');
                    }}>
                    <div className="flex flex-col justify-center items-center m-8 min-w-[200px]" >
                        <img src="/assets/projects/market_maker/holder_bot.jpg" className="btn_bot" />
                        <h1 style={{ color: 'white', fontSize: '24px', marginTop: '16px' }}>Holder Bot</h1>
                    </div>
                    <p className="rounded-[20px] panel-text text-xl m-8 p-4 text-justify">
                        &nbsp;
                        A Holder in the cryptocurrency world refers to an individual who holds onto their digital assets for the long term, regardless of market volatility.
                        They prevent early investors, team members, and other stakeholders from immediately selling off their tokens which can cause price volatility.
                    </p>
                </div>
                <div className="flex flex-row border border-gray-border market-maker-panel m-4 rounded-[24px] cursor-pointer"
                    onClick={() => {
                        navigate('/buysell-bot');
                    }}>
                    <div className="flex flex-col justify-center items-center m-8 min-w-[200px]" >
                        <img src="/assets/projects/market_maker/buy_sell_bot.jpg" className="btn_bot" />
                        <h1 style={{ color: 'white', fontSize: '24px', marginTop: '16px' }}>Buy/Sell Bot</h1>
                    </div>
                    <p className="rounded-[20px] panel-text text-xl m-8 p-4 text-justify">
                        &nbsp;
                        When you open a 'buy' position, you are essentially buying an asset from the market. And when you close your position, you 'sell' it back to the market.
                        You can earn money if you settle down with a science and blockchain technology.
                    </p>
                </div>
            </div>
            <MySidebar2 />
        </div>
    );
}
