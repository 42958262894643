import { useEffect, useState } from "react";
import Modal from "../Base/Modal";

export default function KeyPairGenerateDialog({ isOpen, rotate, onStart, onStop, onCancel }) {

    const handleCancel = () => {
        onCancel();
    };

    const handleStart = () => {
        onStart();
    }

    const handleStop = () => {
        onStop();
    }

    return (
        <Modal isOpen={isOpen} onClose={handleCancel}>
            <div className="flex flex-col p-8 rounded-[20px] font-sans justify-center bg-gray-dark">
                <div className="flex items-center justify-center w-full h-auto px-5 py-3 rounded-t-md revert-dialog ">
                    <div className="font-sans text-white uppercase text-xl">
                        Generating Token Accounts
                    </div>
                </div>
                <div className="flex justify-center m-4">
                    <img src="/assets/hourglass-svg.svg" className={`w-48 h-48 p-4 bg-[rgba(120,120,120,0.2)] rounded-[50%] ${rotate ? 'rotating' : ''}`} alt="spinner" />
                </div>
                <div className="items-center w-full h-auto px-5 py-5 md:py-0 rounded-b-md">
                    <div className="flex items-center justify-center gap-5 my-5 w-full m-auto">
                        {rotate ? <button
                            className="pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-[rgba(255,0,0,0.5)] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white text-2xl uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                            onClick={handleStop}>
                            STOP
                        </button> :
                            <button
                                className="pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-green-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-2xl uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                                onClick={handleStart}>
                                START
                            </button>
                        }
                    </div>

                </div>
            </div>
        </Modal>
    );
}
