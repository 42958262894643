/********************************************************************** The Road to Valhalla! ************************************************************************
 *                                                                                                                                                                   *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌           *
 *  📌                                                                                                                                                  📌         *
 *  📌                 SOLANA LAUNCHPAD WITH JITO BUNDLING = INITIAL BUY FOR THE CLIENTS WITH SIMULATE, DISPERSE, ENABLE & BUY OPERATIONS               📌        *
 *  📌                                                                                                                                                  📌      *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌      *
 *                                                                                                                                                             *
 *  Project Type  : Solana Launchpad with Jito Bundling                                                                                                       *
 *   Project ID   : 2024-3                                                                                                                                   *
 *   Client Info  : Private                                                                                                                                 *
 *    Developer   : GloryDream, Rothschild, HappySmile (Nicknames)                                                                                         *
 *   Source Mode  : 100% Private                                                                                                                          *
 *   Description  : Solana Launchpad-Frontend .                                                                                                          *
 *  Writing Style : P0413-K0408-K1206                                                                                                                   *
 *                                                                                                                                                     *
 ********************************************************************** The Road to Valhalla! *********************************************************
 */
// import Samples node_modules
import { FaDatabase, FaEllipsisV, FaExclamationTriangle, FaQuestion, FaRegCopy, FaSave } from "react-icons/fa";
import { IoIosAddCircle, IoIosDownload } from "react-icons/io";
import { useContext, useState, useEffect } from "react";
import { Popover } from "@headlessui/react";
import { toast } from "react-toastify";
import BigNumber from "bignumber.js";
import axios from "axios";
import bs58 from "bs58";


// import  blockchain integration
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { ellipsisAddress, isValidAddress } from "../utils/methods";
import { getMint, getAccount, getAssociatedTokenAddress } from "@solana/spl-token";
import { Keypair, PublicKey } from "@solana/web3.js";

// import personal layouts and styles

import { AppContext } from "../App";

import KeyPairGenerateDialog from "../components/Dialogs/KeyPairGenerateDialog";

import MySidebar1 from "./MySidebar1";
import MySidebar2 from "./MySidebar2";

export default function KeyPairPage({ className }) {
    const {
        SERVER_URL,
        setLoadingPrompt,
        setOpenLoading,
        user,
        currentPairkey,
        setCurrentPairkey,
        notifyStatus,
        setNotifyStatus,
    } = useContext(AppContext);

    const { connected, publicKey } = useWallet();
    const { connection } = useConnection();

    const [prefix, setPrefix] = useState("");
    const [suffix, setSuffix] = useState("");
    const [withPrefix, setWithPrefix] = useState(true);

    const [flagG, setFlagG] = useState(false);

    const [copied, setCopied] = useState({});
    const [zombieDialog, setZombieDialog] = useState(false);
    const [newWalletDialog, setNewWalletDialog] = useState(false);
    const [dlgKeyPair, setDlgKeyPair] = useState(false);
    const [volumeAmountDialog, setVolumeAmountDialog] = useState(false);
    const [targetWallet, setTargetWallet] = useState("");

    const [walletAllChecked, setWalletAllChecked] = useState(false);
    const [walletChecked, setWalletChecked] = useState([]);
    const [walletSolAmount, setWalletSolAmount] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const disabled = !user._id || user.role === "admin";

    // Object.keys(currentVolume).length === 0 ||

    useEffect(() => {
        if (currentPairkey.tokenAccounts) {
            setWalletChecked(currentPairkey.tokenAccounts.map(() => false));
            setWalletAllChecked(false);
        }
    }, [connection, currentPairkey]);

    useEffect(() => {
        if (notifyStatus.tag === "HAHAHAHA") {
            if (notifyStatus.success) {
                // 
            }
            else {
                // 
            }

            setNotifyStatus({ success: true, tag: "NONE" });
        }
    }, [notifyStatus, currentPairkey._id]);

    const copyToClipboard = async (key, text) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(text);
            toast.success("Copied");
            setCopied({
                ...copied,
                [key]: true,
            });
            setTimeout(() => setCopied({
                ...copied,
                [key]: false,
            }), 2000);
        }
        else
            console.error('Clipboard not supported');
    };

    const handleMouseDown = (e, id) => {
        e.preventDefault();
        setIsDragging(true);
        handleWalletChanged(id, "checked", !walletChecked[id])
    };

    const handleMouseEnter = (id) => {
        if (isDragging) {
            handleWalletChanged(id, "checked", !walletChecked[id])
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleWalletChanged = (index, key, value) => {
        console.log("Wallet changed:", index, key, value);
        if (key === "checked") {
            let newWalletChecked = [...walletChecked];
            newWalletChecked[index] = !newWalletChecked[index];
            setWalletChecked(newWalletChecked);

            let newWalletAllChecked = true;
            for (let i = 0; i < newWalletChecked.length; i++)
                newWalletAllChecked &&= newWalletChecked[i];
            setWalletAllChecked(newWalletAllChecked);

        }
    };

    const handleDeleteAccounts = async () => {
        if (currentPairkey.tokenAccounts) {
            // const votes = currentPairkey.tokenAccounts.filter((item, index) => walletChecked[index])

            setLoadingPrompt("Removing accounts...");
            setOpenLoading(true);
            try {
                const { data } = await axios.post(`${SERVER_URL}/api/v1/pairkey/remove-wallets`,
                    {
                        pairkeyId: currentPairkey._id,
                        walletChecked: walletChecked
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": localStorage.getItem("access-token"),
                        },
                    }
                );
                if (data.success) {
                    toast.success("Removed Successfully");
                    setCurrentPairkey(data.pairkey);
                }
            }
            catch (err) {
                console.log(err);
                toast.warn("Failed to remove wallets!");
            }
            setOpenLoading(false);
        }
    }

    const handleWalletAllChecked = (e) => {

        console.log("Wallet all checked:", e.target.value, walletAllChecked);
        const newWalletAllChecked = !walletAllChecked;

        setWalletAllChecked(newWalletAllChecked);
        setWalletChecked(currentPairkey.tokenAccounts.map(() => newWalletAllChecked));
    };

    const handleDownloadWallets = async () => {
        // if (!currentPairkey.token) {
        //     toast.warn("Select the volume");
        //     return;
        // }

        // setLoadingPrompt("Downloading wallets...");
        // setOpenLoading(true);
        // try {
        //     const { data } = await axios.post(`${SERVER_URL}/api/v1/volume/download-wallets`,
        //         {
        //             volumeId: currentPairkey._id,
        //         },
        //         {
        //             headers: {
        //                 "Content-Type": "application/json",
        //                 "MW-USER-ID": localStorage.getItem("access-token"),
        //             },
        //         }
        //     );

        //     const downloadFile = (data, fileName) => {
        //         const url = window.URL.createObjectURL(new Blob([data]));
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute(
        //             'download',
        //             fileName,
        //         );

        //         // Append to html link element page
        //         document.body.appendChild(link);

        //         // Start download
        //         link.click();

        //         // Clean up and remove the link
        //         link.parentNode.removeChild(link);
        //     };

        //     downloadFile(data, `wallets_volume.csv`);
        // }
        // catch (err) {
        //     console.log(err);
        //     toast.warn("Failed to download wallets!");
        // }
        // setOpenLoading(false);
    };

    const openKeypairDialog = async () => {
        if (withPrefix && prefix == "") {
            toast.warn("Input the Prefix correctly");
            return;
        }
        if (!withPrefix && suffix == "") {
            toast.warn("Input the Suffix correctly");
            return;
        }
        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/pairkey/status`,
                {
                    pairkeyId: currentPairkey._id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );

            setFlagG(data.status);
        } catch (err) {
            console.log(err);
            // toast.warn("Failed to generate!");
        }
        setDlgKeyPair(true);
    }

    const handleStopKeyPair = async () => {
        try {
            setFlagG(false);
            const { data } = await axios.post(`${SERVER_URL}/api/v1/pairkey/generate-pairkey-stop`,
                {
                    pairkeyId: currentPairkey._id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            if (data.success) {
                toast.success("Generating stopped");
                setCurrentPairkey(data.pairkey);
            }
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to generate!");
        }
    };

    const handleStartPairkey = async () => {
        try {
            setFlagG(true);
            axios.post(`${SERVER_URL}/api/v1/pairkey/generate-pairkey`,
                {
                    pairkeyId: currentPairkey._id,
                    options: {
                        prefix: prefix,
                        suffix: suffix,
                        withPrefix: withPrefix
                    }
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            // toast.warn("Failed to generate!");
        }
    };

    return (
        <div className="flex flex-row">
            <MySidebar1 />
            <div className={`${className} w-full m-8 justify-between flex flex-col text-white rounded-[4px] border border-gray-highlight p-4 pb-3 w-full`}>
                <KeyPairGenerateDialog
                    isOpen={dlgKeyPair}
                    rotate={flagG}
                    onStart={handleStartPairkey}
                    onStop={handleStopKeyPair}
                    onCancel={() => {
                        if (flagG) {
                            toast.warn("Generating accounts now...");
                        } else {
                            setDlgKeyPair(false);
                        }
                    }} />
                <div className="flex flex-col">
                    <div className="w-full mt-[6px] grid grid-cols-12 gap-3">
                        <div className="col-span-12 md:col-span-6 2xl:col-span-3">
                            <div className="flex flex-row gap-2 items-center">
                                <input
                                    name="PrefixSuffix"
                                    type="radio"
                                    className="w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0"
                                    onChange={(e) => {
                                        setWithPrefix(true);
                                    }}
                                />
                                <div className="font-sans text-xs uppercase text-gray-normal " >
                                    Starts With<span className="pl-1 text-yellow-normal cursor-pointer" onClick={() => {
                                        setWithPrefix(true);
                                    }}>*</span>
                                </div>
                            </div>
                            <input
                                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                placeholder="Enter Prefix"
                                value={prefix}
                                maxLength={4}  // Limit input to 4 characters
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^[A-Za-z0-9]*$/; // Allow only digits and letters

                                    if (value.length > 4) {
                                        toast.warn("Prefix cannot exceed 4 letters");
                                    } else if (!regex.test(value)) {
                                        toast.warn("Prefix can only contain letters and digits");
                                    } else {
                                        setPrefix(value);
                                    }
                                }}
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6 2xl:col-span-3">
                            <div className="flex flex-row gap-2 items-center">
                                <input
                                    name="PrefixSuffix"
                                    type="radio"
                                    className="w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0"
                                    onChange={(e) => {
                                        setWithPrefix(false);
                                    }}
                                />
                                <div className="font-sans text-xs uppercase text-gray-normal" >
                                    Ends With<span className="pl-1 text-yellow-normal cursor-pointer" onClick={() => {
                                        setWithPrefix(false);
                                    }}>*</span>
                                </div>
                            </div>
                            <input
                                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                placeholder="Enter Suffix"
                                value={suffix}
                                maxLength={4}  // Limit input to 4 characters
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^[A-Za-z0-9]*$/; // Allow only digits and letters

                                    if (value.length > 4) {
                                        toast.warn("Prefix cannot exceed 4 letters");
                                    } else if (!regex.test(value)) {
                                        toast.warn("Prefix can only contain letters and digits");
                                    } else {
                                        setSuffix(value);
                                    }
                                }}
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6 2xl:col-span-3">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Generating Token Accounts
                            </div>

                            <div className={`flex items-center justify-between outline-none border border-gray-border text-gray-normal font-sans text-sm pl-2.5 bg-transparent w-full h-button mt-1 pr-1 ${disabled && "text-gray-border border-gray-highlight"}`}>
                                <button
                                    className="w-full h-button rounded-[4px] justify-left items-center gap-1 inline-flex active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap items-center "
                                    disabled={disabled}
                                    onClick={openKeypairDialog}
                                >
                                    <IoIosAddCircle className="text-lg text-green-normal mr-1" />
                                    Generate Token Accounts
                                </button>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 2xl:col-span-3">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Downloading Token Accounts
                            </div>
                            <div className={`flex items-center justify-left outline-none border border-gray-border text-gray-normal font-sans text-sm pl-2.5 bg-transparent w-full h-button mt-1 pr-1 ${disabled && "text-gray-border border-gray-highlight"}`}>
                                <button
                                    className="pl-3 pr-4 h-button rounded-[4px] justify-center items-center gap-1 inline-flex active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap items-center"
                                    disabled={disabled}
                                    onClick={handleDownloadWallets}
                                >
                                    <IoIosDownload className="text-lg text-green-normal mr-1" />
                                    Download Accounts
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between w-full gap-2 mt-3 mb-3 font-sans">
                        <div className="flex items-center gap-3 font-sans text-sm text-gray-normal">
                            <div>
                                Selected: <span className="text-white">{walletChecked.filter(wal => wal).length}</span>
                            </div>
                        </div>
                        <div className="flex flex-col justify-end gap-2 lg:items-center lg:flex-row">
                            <button
                                className="bg-[rgba(255,0,0,0.5)] px-4 h-button rounded-[4px] justify-center items-center gap-1 inline-flex active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                                disabled={disabled}
                                onClick={handleDeleteAccounts}
                            >
                                Delete Accounts
                            </button>
                        </div>
                    </div>
                    <div className="w-full overflow-visible font-sans">
                        <div className="flex flex-col w-full h-full text-white bg-transparent bg-clip-border">
                            <div className="relative border border-gray-highlight">
                                <div className="h-[calc(100vh-435px)] 2xl:h-[calc(100vh-365px)] overflow-y-auto">
                                    {(!currentPairkey.tokenAccounts || currentPairkey.tokenAccounts.length === 0) &&
                                        <div className="absolute flex items-center justify-center gap-2 my-3 text-base font-bold text-center uppercase -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-gray-border">
                                            <FaExclamationTriangle className="text-sm opacity-50 text-green-normal" /> No Token Accounts
                                        </div>
                                    }
                                    <table className="min-w-[700px] w-full text-xs">
                                        <thead className=" text-gray-normal">
                                            <tr className="uppercase h-7 bg-[#262626] sticky top-0 z-10">
                                                <th className="w-8 text-center">
                                                    <div className="flex items-center justify-center">
                                                        <input type="checkbox"
                                                            className="w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0"
                                                            checked={walletAllChecked}
                                                            onChange={handleWalletAllChecked} />
                                                    </div>
                                                </th>
                                                <th className="w-8">
                                                    <p className="leading-none text-center">
                                                        #
                                                    </p>
                                                </th>
                                                <th className="w-full">
                                                    <p className="leading-none text-center">
                                                        Public Key
                                                    </p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-xs text-white" onMouseLeave={handleMouseUp}>
                                            {
                                                currentPairkey.tokenAccounts &&
                                                currentPairkey.tokenAccounts.map((item, index) => {
                                                    return (
                                                        <tr key={index}
                                                            className={`${index % 2 === 1 && "bg-[#ffffff02]"} hover:bg-[#ffffff08] ${walletChecked[index] && "!bg-[#00000030]"} h-8`}
                                                        >
                                                            <td className="text-center"
                                                                onMouseDown={(e) => handleMouseDown(e, index)}
                                                                onMouseEnter={() => handleMouseEnter(index)}
                                                                onMouseUp={handleMouseUp}
                                                            >
                                                                <div className="flex items-center justify-center">
                                                                    <input type="checkbox"
                                                                        className="w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0"
                                                                        checked={walletChecked[index]}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="">
                                                                <p className="leading-none text-center text-gray-normal">
                                                                    {index + 1}
                                                                </p>
                                                            </td>
                                                            <td className="">
                                                                <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal text-gray-normal">
                                                                    <p className="bg-transparent border-none outline-none">
                                                                        {item.publicKey}
                                                                    </p>
                                                                    {
                                                                        copied["wallet_" + index] ?
                                                                            (<svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                                                            </svg>) :
                                                                            (<FaRegCopy className="w-3 h-3 transition ease-in-out transform cursor-pointer active:scale-95 duration-90" onClick={() => copyToClipboard("wallet_" + index, item.address)} />)
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <MySidebar2></MySidebar2>
        </div>
    );
}