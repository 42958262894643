/********************************************************************** The Road to be Rich  ! ************************************************************************
 *                                                                                                                                                                   *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌           *
 *  📌                                                                                                                                                  📌         *
 *  📌                 SOLANA LAUNCHPAD WITH JITO BUNDLING = INITIAL BUY FOR THE CLIENTS WITH SIMULATE, DISPERSE, ENABLE & BUY OPERATIONS               📌        *
 *  📌                                                                                                                                                  📌      *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌      *
 *                                                                                                                                                             *
 *  Project Type  : Solana Launchpad with Jito Bundling                                                                                                       *
 *   Project ID   : 2024-3                                                                                                                                   *
 *   Client Info  : Private                                                                                                                                 *
 *    Developer   : GloryDream, Rothschild, HappySmile (Nicknames)                                                                                         *
 *   Source Mode  : 100% Private                                                                                                                          *
 *   Description  : Solana Launchpad-Frontend .                                                                                                          *
 *  Writing Style : P0413-K0408-K1206                                                                                                                   *
 *                                                                                                                                                     *
 ********************************************************************** The Road to be Rich  ! *********************************************************
 */

// import Samples node_modules
import { createContext, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import BigNumber from "bignumber.js";
import io from 'socket.io-client';
import axios from "axios";

// import  blockchain integration
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { getMint, getAssociatedTokenAddressSync, TOKEN_PROGRAM_ID, AccountLayout } from "@solana/spl-token";
import { getTokenListByOwner } from "./utils/solana";

// import personal layouts and styles
import "./App.css";

import LoginPage from "./pages/LoginPage";
import NavBar from "./components/NavBar";
import DashboardPage from "./pages/DashboardPage";
import MyProjects from "./pages/MyProjectsPage";

import TokenMenuPage from "./pages/TokenMenuPage";
import KeyPairPage from "./pages/KeyPairPage";
import CreateTokenPage from "./pages/CreateTokenPage";
import SetAuthorityPage from "./pages/SetAuthorityPage";
import OpenBookMarketPage from "./pages/OpenBookMarketPage";
import ManageLpPage from "./pages/ManageLpPage";
import TokenAccountPage from "./pages/TokenAccountPage";
import BuyPage from "./pages/BuyPage";
import SellPage from "./pages/SellPage";
import TransferPage from "./pages/TransferPage";
import LogPage from "./pages/LogPage";
import MarketMaker from "./pages/MarketMaker";
import VolumeBot from "./pages/Bot_Volume";
import HolderBot from "./pages/Bot_Holder";
import BuySellBot from "./pages/Bot_BuySell";

import LoadingDialog from "./components/Dialogs/LoadingDialog";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SERVER_SOCKET_URL = process.env.REACT_APP_SERVER_SOCKET_URL;

if (!SERVER_URL) {
    console.error('@@@@@@@@@@@@@@@@@@ SERVER_URL is not defined. Please check your environment variables.');
}

// Global variables

export const AppContext = createContext(null);

function App() {
    // internal variables
    const navigate = useNavigate();
    const location = useLocation();

    const { connection } = useConnection();
    const { connected, publicKey } = useWallet();

    const [loadingPrompt, setLoadingPrompt] = useState("");
    const [openLoading, setOpenLoading] = useState(false);

    const [user, setUser] = useState({});
    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState([]);
    const [currentProject, setCurrentProject] = useState({});
    const [currentVolume, setCurrentVolume] = useState({});
    const [currentHolder, setCurrentHolder] = useState({});
    const [currentPairkey, setCurrentPairkey] = useState({});

    const [logs, setLogs] = useState([]);
    const [assets, setAssets] = useState([]);
    const [webSocket, setWebSocket] = useState(null);
    const [notifyStatus, setNotifyStatus] = useState({ success: true, tag: "NONE" });
    const [extraWallets, setExtraWallets] = useState([]);
    const [emails, setEmails] = useState([]);
    const [jitoSigners, setJitoSigners] = useState([]);
    const [walletBalanceData, setWalletBalanceData] = useState({ address: "", token: [], sol: [] });
    const [volumeWalletBalanceData, setVolumeWalletBalanceData] = useState({ address: "", token: [], sol: [] });
    const [holderWalletBalanceData, setHolderWalletBalanceData] = useState({ address: "", token: [], sol: [] });
    const [teamWalletBalanceData, setTeamWalletBalanceData] = useState({ address: "", token: [], sol: [] });
    const [breadCrumb, setBreadCrumb] = useState([]);

    // Starting Web Socket
    const openWebSocket = (userId) => {
        // const ws = new io("SERVER_URL");
        const ws = new io(SERVER_SOCKET_URL);
        console.log("Starting openWebSocket ... : ", SERVER_URL);

        ws.on("connect", () => {
            console.log("Websocket established ... : ", SERVER_URL);
            ws.emit("NEW_USER", userId);
        });

        ws.on("BUY_PENDING", async (value) => {
            setNotifyStatus({ success: true, tag: "BUY_PENDING" });
        });

        ws.on("SIMULATE_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "SIMULATE_COMPLETED", data: m.data });
            else
                setNotifyStatus({ success: false, tag: "SIMULATE_COMPLETED", error: m.error });
        });

        ws.on("DISPERSE_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "DISPERSE_COMPLETED", project: m.project });
            else
                setNotifyStatus({ success: false, tag: "DISPERSE_COMPLETED" });
        });

        ws.on("BUY_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "BUY_COMPLETED", project: m.project });
            else
                setNotifyStatus({ success: false, tag: "BUY_COMPLETED" });
        });

        ws.on("VOLUME_BOT", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "VOLUME_BOT", volume: m.volume });
            else
                setNotifyStatus({ success: false, tag: "VOLUME_BOT" });
        });

        ws.on("VOLUME_BOT_STOPPED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "VOLUME_BOT_STOPPED", volume: m.volume });
            else
                setNotifyStatus({ success: false, tag: "VOLUME_BOT_STOPPED" });
        });

        ws.on("HOLDER_BOT", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "HOLDER_BOT", volume: m.volume });
            else
                setNotifyStatus({ success: false, tag: "HOLDER_BOT" });
        });

        ws.on("HOLDER_BOT_STOPPED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "HOLDER_BOT_STOPPED", volume: m.volume });
            else
                setNotifyStatus({ success: false, tag: "HOLDER_BOT_STOPPED" });
        });

        ws.on("DISPERSE_VOLUME_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "DISPERSE_VOLUME_COMPLETED", project: m.project });
            else
                setNotifyStatus({ success: false, tag: "DISPERSE_VOLUME_COMPLETED" });
        });

        ws.on("DISPERSE_HOLDER_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "DISPERSE_HOLDER_COMPLETED", holder: m.holder });
            else
                setNotifyStatus({ success: false, tag: "DISPERSE_HOLDER_COMPLETED" });
        });

        ws.on("BUY_HOLDER_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "BUY_HOLDER_COMPLETED", holder: m.holder });
            else
                setNotifyStatus({ success: false, tag: "BUY_HOLDER_COMPLETED" });
        });

        ws.on("SELL_HOLDER_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "SELL_HOLDER_COMPLETED", holder: m.holder });
            else
                setNotifyStatus({ success: false, tag: "SELL_HOLDER_COMPLETED" });
        });

        ws.on("BUY_VOLUME_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "BUY_VOLUME_COMPLETED", volume: m.volume });
            else
                setNotifyStatus({ success: false, tag: "BUY_VOLUME_COMPLETED" });
        });

        ws.on("SELL_VOLUME_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "SELL_VOLUME_COMPLETED", volume: m.volume });
            else
                setNotifyStatus({ success: false, tag: "SELL_VOLUME_COMPLETED" });
        });

        ws.on("BUY_SELL_VOLUME_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "BUY_SELL_VOLUME_COMPLETED", volume: m.volume });
            else
                setNotifyStatus({ success: false, tag: "BUY_SELL_VOLUME_COMPLETED" });
        });
        ws.on("DISPERSE_TOKENS_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            setNotifyStatus({ success: m.message === "OK", tag: "DISPERSE_TOKENS_COMPLETED", project: m.project });
        });

        ws.on("SELL_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            setNotifyStatus({ success: m.message === "OK", tag: "SELL_COMPLETED", project: m.project });
        });

        ws.on("TRANSFER_COMPLETED", async (value) => {
            const m = JSON.parse(value);
            setNotifyStatus({ success: m.message === "OK", tag: "TRANSFER_COMPLETED", project: m.project });
        });

        ws.on("COLLECT_ALL_SOL", async (value) => {
            const m = JSON.parse(value);
            setNotifyStatus({ success: m.message === "OK", tag: "COLLECT_ALL_SOL", project: m.project });
        });

        ws.on("COLLECT_ALL_FEE", async (value) => {
            const m = JSON.parse(value);
            if (m.message === "OK")
                setNotifyStatus({ success: true, tag: "COLLECT_ALL_FEE" });
            else
                setNotifyStatus({ success: false, tag: "COLLECT_ALL_FEE" });
        });

        ws.on("INSPECT_LOG", (value) => {
            console.log("SERVER:", value);
        });

        ws.on("ADD_LOG", (value) => {
            const m = JSON.parse(value);
            setNotifyStatus({ success: true, tag: "ADD_LOG", log: m });
        });

        ws.on("disconnect", () => {
            console.log('WebSocket connection closed');
            // setConnected(false);
        });

        setWebSocket(ws);
    };

    const closeWebSocket = () => {
        if (webSocket)
            webSocket.close();
        setWebSocket(null);
    };

    const updateAllBalances = async (connection, token, wallets, teamWallets) => {
        console.log("Updating all balances...", token, wallets, teamWallets);

        let tokenBalances = [];
        let solBalances = [];
        let teamTokenBalances = [];
        let teamSolBalances = [];
        try {
            const mint = new PublicKey(token);
            const mintInfo = await getMint(connection, mint);
            const tokenBalanceAta = await connection.getMultipleAccountsInfo(wallets.map(item => {
                return getAssociatedTokenAddressSync(mint, new PublicKey(item));
            }));
            tokenBalances = tokenBalanceAta.map(item => {
                if (item && item.owner && item.owner.equals(TOKEN_PROGRAM_ID))
                    return Number(new BigNumber(AccountLayout.decode(item.data).amount.toString() + "e-" + mintInfo.decimals.toString()).toString()).toFixed(4);
                else
                    return "0.0000";
            });

            if (teamWallets) {
                const teamTokenBalanceAta = await connection.getMultipleAccountsInfo(teamWallets.map(item => {
                    return getAssociatedTokenAddressSync(mint, new PublicKey(item));
                }));
                teamTokenBalances = teamTokenBalanceAta.map(item => {
                    if (item && item.owner && item.owner.equals(TOKEN_PROGRAM_ID))
                        return Number(new BigNumber(AccountLayout.decode(item.data).amount.toString() + "e-" + mintInfo.decimals.toString()).toString()).toFixed(4);
                    else
                        return "0.0000";
                });
            }
        }
        catch (err) {
            console.log(err);
            tokenBalances = wallets.map(() => "0");
            teamTokenBalances = teamWallets ? teamWallets.map(() => "0") : [];
        }

        try {
            const solBalanceAta = await connection.getMultipleAccountsInfo(wallets.map(item => new PublicKey(item)));
            solBalances = solBalanceAta.map(item => {
                if (item)
                    return Number(new BigNumber(item.lamports.toString() + "e-9").toString()).toFixed(4);
                else
                    return "0.0000";
            });

            if (teamWallets) {
                const teamSolBalanceAta = await connection.getMultipleAccountsInfo(teamWallets.map(item => new PublicKey(item)));
                teamSolBalances = teamSolBalanceAta.map(item => {
                    if (item)
                        return Number(new BigNumber(item.lamports.toString() + "e-9").toString()).toFixed(4);
                    else
                        return "0.0000";
                });
            }
        }
        catch (err) {
            console.log(err);
            solBalances = wallets.map(() => "0");
            teamSolBalances = teamWallets ? teamWallets.map(() => "0") : [];
        }

        console.log("Tokens:", tokenBalances, "SOLs:", solBalances);
        console.log("Team Tokens:", teamTokenBalances, "SOLs:", teamSolBalances);

        setWalletBalanceData({ address: token, token: tokenBalances, sol: solBalances });
        setTeamWalletBalanceData({ address: token, token: teamTokenBalances, sol: teamSolBalances });
    };

    const updateVolumeBalances = async (connection, token, wallets) => {
        console.log("Updating Volume balances...", token, wallets);

        let tokenBalances = [];
        let solBalances = [];
        try {
            const mint = new PublicKey(token);
            const mintInfo = await getMint(connection, mint);
            const tokenBalanceAta = await connection.getMultipleAccountsInfo(wallets.map(item => {
                return getAssociatedTokenAddressSync(mint, new PublicKey(item));
            }));
            tokenBalances = tokenBalanceAta.map(item => {
                if (item && item.owner && item.owner.equals(TOKEN_PROGRAM_ID))
                    return Number(new BigNumber(AccountLayout.decode(item.data).amount.toString() + "e-" + mintInfo.decimals.toString()).toString()).toFixed(4);
                else
                    return "0.0000";
            });
        }
        catch (err) {
            console.log(err);
            tokenBalances = wallets.map(() => "0");
        }

        try {
            const solBalanceAta = await connection.getMultipleAccountsInfo(wallets.map(item => new PublicKey(item)));
            solBalances = solBalanceAta.map(item => {
                if (item)
                    return Number(new BigNumber(item.lamports.toString() + "e-9").toString()).toFixed(4);
                else
                    return "0.0000";
            });
        }
        catch (err) {
            console.log(err);
            solBalances = wallets.map(() => "0");
        }

        console.log("Tokens:", tokenBalances, "SOLs: jjjj", solBalances);
        setVolumeWalletBalanceData({ address: token, token: tokenBalances, sol: solBalances });
    };

    const updateHolderBalances = async (connection, token, wallets) => {
        console.log("Updating Holder balances...", token, wallets);

        let tokenBalances = [];
        let solBalances = [];
        try {
            const mint = new PublicKey(token);
            const mintInfo = await getMint(connection, mint);
            const tokenBalanceAta = await connection.getMultipleAccountsInfo(wallets.map(item => {
                return getAssociatedTokenAddressSync(mint, new PublicKey(item));
            }));
            tokenBalances = tokenBalanceAta.map(item => {
                if (item && item.owner && item.owner.equals(TOKEN_PROGRAM_ID))
                    return Number(new BigNumber(AccountLayout.decode(item.data).amount.toString() + "e-" + mintInfo.decimals.toString()).toString()).toFixed(4);
                else
                    return "0.0000";
            });
        }
        catch (err) {
            console.log(err);
            tokenBalances = wallets.map(() => "0");
        }

        try {
            const solBalanceAta = await connection.getMultipleAccountsInfo(wallets.map(item => new PublicKey(item)));
            solBalances = solBalanceAta.map(item => {
                if (item)
                    return Number(new BigNumber(item.lamports.toString() + "e-9").toString()).toFixed(4);
                else
                    return "0.0000";
            });
        }
        catch (err) {
            console.log(err);
            solBalances = wallets.map(() => "0");
        }

        console.log("Tokens:", tokenBalances, "SOLs: jjjj", solBalances);
        setHolderWalletBalanceData({ address: token, token: tokenBalances, sol: solBalances });
    };

    const loadAllProjects = async () => {
        let newProjects = [];
        setLoadingPrompt("Loading all projects...");
        setOpenLoading(true);
        try {
            console.log("Loading all projects...");
            const { data } = await axios.get(`${SERVER_URL}/api/v1/project/load-all`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            if (data.projects)
                newProjects = data.projects;
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to load projects");
        }

        setOpenLoading(false);
        setProjects(newProjects);
        setCurrentProject({});
    };

    const loadVolume = async () => {
        let newVolume = {};
        setLoadingPrompt("Loading volume...");
        setOpenLoading(true);
        try {
            console.log("Loading volume...");
            const { data } = await axios.get(`${SERVER_URL}/api/v1/volume/load`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            if (data.volume)
                newVolume = data.volume;
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to load volumes");
        }

        setOpenLoading(false);
        setCurrentVolume(newVolume);
    };

    const loadHolder = async () => {
        let newHolder = {};
        setLoadingPrompt("Loading holder...");
        setOpenLoading(true);
        try {
            console.log("Loading holder...");
            const { data } = await axios.get(`${SERVER_URL}/api/v1/holder/load`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            if (data.holder)
                newHolder = data.holder;
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to load holders");
        }

        setOpenLoading(false);
        setCurrentHolder(newHolder);
    };

    const loadPairkey = async () => {
        setLoadingPrompt("Loading pairkeys...");
        setOpenLoading(true);
        try {
            console.log("Loading pairkeys...");
            const { data } = await axios.get(`${SERVER_URL}/api/v1/pairkey/load`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            if (data.pairkey)
                setCurrentPairkey(data.pairkey)
            else
                setCurrentPairkey({});
        }
        catch (err) {
            console.log(err);
            setCurrentPairkey({});
            toast.warn("Failed to load pairkey");
        }

        setOpenLoading(false);
    };

    const loadAllUsers = async () => {
        let newUsers = [];
        setLoadingPrompt("Loading all users...");
        setOpenLoading(true);
        try {
            console.log("Loading all users...");
            const { data } = await axios.get(`${SERVER_URL}/api/v1/user/load-all`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            if (data.users)
                newUsers = data.users;
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to load users");
        }

        setOpenLoading(false);
        setUsers(newUsers);
    };

    const loadAllEmails = async () => {
        let newEmails = [];
        setLoadingPrompt("Loading all emails...");
        setOpenLoading(true);
        try {
            console.log("Loading all emails...");
            const { data } = await axios.get(`${SERVER_URL}/api/v1/misc/load-emails`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            if (data.emails)
                newEmails = data.emails;
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to load users");
        }

        setOpenLoading(false);
        setEmails(newEmails);
    };

    const loadAllJitoSigners = async () => {
        let newJitoSigners = [];
        setLoadingPrompt("Loading all jito-signers...");
        setOpenLoading(true);
        try {
            console.log("Loading all jito-signers...");
            const { data } = await axios.get(`${SERVER_URL}/api/v1/misc/load-jito-signers`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            if (data.signers)
                newJitoSigners = data.signers;
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to load users");
        }

        setOpenLoading(false);
        setJitoSigners(newJitoSigners);
    };

    const updateProject = (project) => {
        const newProjects = [...projects];
        for (let i = 0; i < newProjects.length; i++) {
            if (project._id === newProjects[i]._id) {
                newProjects[i] = project;
                break;
            }
        }
        setProjects(newProjects);
    };

    const initAllData = async (accessToken, user) => {
        let newUsers = [];
        let newProjects = [];
        let newVolume = {};
        let newHolder = {};
        let newPairkey = {};

        let newEmails = [];
        let newJitoSigners = [];
        let newExtraWallets = [];
        let newLogs = [];

        setLoadingPrompt("Initializing...");
        setOpenLoading(true);

        if (user.role === "admin") {
            try {
                console.log("Loading all users...");
                const { data } = await axios.get(`${SERVER_URL}/api/v1/user/load-all`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": accessToken,
                        },
                    }
                );
                if (data.users)
                    newUsers = data.users;
            }
            catch (err) {
                console.log(err);
                toast.warn("Failed to load users");
            }
        }

        try {
            console.log("Loading all projects...");
            const { data } = await axios.get(`${SERVER_URL}/api/v1/project/load-all`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": accessToken,
                    },
                }
            );
            if (data.projects)
                newProjects = data.projects;
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to load projects");
        }

        try {
            console.log("Loading volume...");
            const { data } = await axios.get(`${SERVER_URL}/api/v1/volume/load`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": accessToken,
                    },
                }
            );
            if (data.volume)
                newVolume = data.volume;
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to load volumes");
        }

        try {
            console.log("Loading holder...");
            const { data } = await axios.get(`${SERVER_URL}/api/v1/holder/load`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": accessToken,
                    },
                }
            );
            if (data.holder)
                newHolder = data.holder;
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to load volumes");
        }

        try {
            console.log("Loading pairkeys...");
            const { data } = await axios.get(`${SERVER_URL}/api/v1/pairkey/load`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            if (data.pairkey)
                newPairkey = data.pairkey;
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to load pairkey");
        }


        if (user.role === "admin") {
            try {
                console.log("Loading all emails...");
                const { data } = await axios.get(`${SERVER_URL}/api/v1/misc/load-emails`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": accessToken,
                        },
                    }
                );
                if (data.emails)
                    newEmails = data.emails;
            }
            catch (err) {
                console.log(err);
                toast.warn("Failed to load emails");
            }
        }

        if (user.role === "admin") {
            try {
                console.log("Loading all jito-signers...");
                const { data } = await axios.get(`${SERVER_URL}/api/v1/misc/load-jito-signers`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": accessToken,
                        },
                    }
                );
                if (data.signers)
                    newJitoSigners = data.signers;
            }
            catch (err) {
                console.log(err);
                toast.warn("Failed to load jito-signers");
            }
        }

        if (user.role === "admin") {
            try {
                console.log("Loading all extra-wallets...");
                const { data } = await axios.get(`${SERVER_URL}/api/v1/misc/load-extra-wallets`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": accessToken,
                        },
                    }
                );
                newExtraWallets = data.contacts;
            }
            catch (err) {
                console.log(err);
                toast.warn("Failed to load extra-wallets");
            }
        }

        if (user.role === "admin") {
            try {
                console.log("Loading all logs...");
                const { data } = await axios.get(`${SERVER_URL}/api/v1/misc/load-all-logs`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": accessToken,
                        },
                    }
                );
                newLogs = data.logs;
            }
            catch (err) {
                console.log(err);
                toast.warn("Failed to load logs");
            }
        }

        setOpenLoading(false);
        setProjects(newProjects);
        setCurrentProject({});
        setCurrentVolume(newVolume);
        setCurrentHolder(newHolder);
        setCurrentPairkey(newPairkey);

        if (user.role === "admin") {
            setUsers(newUsers);
            setEmails(newEmails);
            setJitoSigners(newJitoSigners);
            setExtraWallets(newExtraWallets);
            setLogs(newLogs);
        }
    };

    const logout = async () => {
        console.log("Logging out...");

        setLoadingPrompt("Logging out...");
        setOpenLoading(true);
        try {
            await axios.get(`${SERVER_URL}/api/v1/user/logout`, {
                headers: {
                    'MW-USER-ID': localStorage.getItem("access-token")
                }
            });
            localStorage.removeItem("access-token");

            setUsers([]);
            setProjects([]);
            setCurrentProject({});
            setCurrentVolume({});
            setUser({});
            closeWebSocket();
        }
        catch (error) {
            console.log(error);
            toast.warn("Failed to logout");
        }
        setOpenLoading(false);
    };

    useEffect(() => {
        if (currentProject.token || (currentProject.wallets && currentProject.wallets.length > 0) || (currentProject.teamWallets && currentProject.teamWallets.length > 0)) {
            const wallets = currentProject.wallets.map(item => item.address);
            const teamWallets = currentProject.teamWallets ? currentProject.teamWallets.map(item => item.address) : [];
            updateAllBalances(connection, currentProject.token.address, wallets, teamWallets);
        }
        else {
            setWalletBalanceData({ address: "", token: [], sol: [] });
            setTeamWalletBalanceData({ address: "", token: [], sol: [] });
        }
    }, [connection, currentProject.token, currentProject.wallets, currentProject.teamWallets]);

    useEffect(() => {
        if (currentVolume.token || (currentVolume.wallets && currentVolume.wallets.length > 0)) {
            const wallets = currentVolume.wallets.map(item => item.address);
            updateVolumeBalances(connection, currentVolume.token.address, wallets);
        }
        else {
            setWalletBalanceData({ address: "", token: [], sol: [] });
        }
    }, [connection, currentVolume.token, currentVolume.wallets]);

    useEffect(() => {
        if (currentHolder.token || (currentHolder.wallets && currentHolder.wallets.length > 0)) {
            const wallets = currentHolder.wallets.map(item => item.address);
            updateHolderBalances(connection, currentHolder.token.address, wallets);
        }
        else {
            setWalletBalanceData({ address: "", token: [], sol: [] });
        }
    }, [connection, currentHolder.token, currentHolder.wallets]);

    useEffect(() => {
        const loadUser = async (accessToken) => {
            try {
                const { data } = await axios.get(`${SERVER_URL}/api/v1/user/me`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": accessToken,
                        },
                    }
                );
                if (data.success)
                    setUser(data.user);
            }
            catch (err) {
                console.log(err);
                setUser({});
            }
        };

        loadUser(localStorage.getItem("access-token"));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if (!user._id) {
            if (location.pathname !== "/" &&
                location.pathname !== "/login" &&
                location.pathname !== "/register") {
                navigate("/");
            }
        }
        else {
            if (location.pathname !== "/dashboard" &&
                location.pathname !== "/myprojects" &&
                location.pathname !== "/market-maker" &&
                location.pathname !== "/volume-bot" &&
                location.pathname !== "/holder-bot" &&
                location.pathname !== "/buysell-bot" &&
                location.pathname !== "/maker-bot" &&

                location.pathname !== "/token-menu" &&
                location.pathname !== "/key-pair" &&
                location.pathname !== "/create-token" &&
                location.pathname !== "/set-authority" &&
                location.pathname !== "/openbook" &&
                location.pathname !== "/manage-lp" &&
                location.pathname !== "/token-account" &&
                location.pathname !== "/buy" &&
                location.pathname !== "/sell" &&
                location.pathname !== "/transfer" &&

                (location.pathname !== "/log" || user.role !== "admin")) {
                navigate("/dashboard");
            }
        }
    }, [location, navigate, user]);

    useEffect(() => {
        if (location.pathname === "/dashboard")
            setBreadCrumb(["Home", "Dashboard"]);
        else if (location.pathname === "/market-maker") {
            setBreadCrumb(["Home", "Market Maker"]);
        }
        else if (location.pathname === "/volume-bot") {
            setBreadCrumb(["Home", "Market Maker", "Volume Bot"]);
        }
        else if (location.pathname === "/holder-bot") {
            setBreadCrumb(["Home", "Market Maker", "Holder Bot"]);
        }
        else if (location.pathname === "/buysell-bot") {
            setBreadCrumb(["Home", "Market Maker", "Buy/Sell Bot"]);
        }
        else if (location.pathname === "/maker-bot") {
            setBreadCrumb(["Home", "Market Maker", "Maker Bot"]);
        }
        else if (location.pathname === "/myprojects") {
            setBreadCrumb(["Home", "My Projects"]);
        }
        else if (location.pathname === "/buy")
            setBreadCrumb(["Home", "My Projects", "Buy"]);
        else if (location.pathname === "/sell")
            setBreadCrumb(["Home", "My Projects", "Sell"]);
        else if (location.pathname === "/transfer")
            setBreadCrumb(["Home", "My Projects", "Transfer"]);

        else if (location.pathname === "/token-menu")
            setBreadCrumb(["Home", "Manage Token", "Token Menu"]);

        else if (location.pathname === "/key-pair")
            setBreadCrumb(["Home", "Manage Token", "Token Menu", "Key Pair"]);

        else if (location.pathname === "/create-token")
            setBreadCrumb(["Home", "Manage Token", "Token Menu", "Create Token"]);

        else if (location.pathname === "/set-authority")
            setBreadCrumb(["Home", "Manage Token", "Set Authority"]);
        else if (location.pathname === "/openbook")
            setBreadCrumb(["Home", "Manage Token", "Create OpenBook Market"]);
        else if (location.pathname === "/manage-lp")
            setBreadCrumb(["Home", "Manage Token", "Manage LP"]);
        else if (location.pathname === "/token-account")
            setBreadCrumb(["Home", "Manage Token", "Token Account"]);
        else if (location.pathname === "/log")
            setBreadCrumb(["Home", "Log"]);
    }, [location.pathname]);

    useEffect(() => {
        if (user._id) {
            console.log("Succeed to login");
            toast.success("Login Successfully");

            openWebSocket(user._id);

            const accessToken = localStorage.getItem("access-token");
            initAllData(accessToken, user);
        }
        else
            console.log("Logged out");
    }, [user._id]);

    useEffect(() => {
        if (notifyStatus.tag === "COLLECT_ALL_FEE") {
            if (notifyStatus.success)
                toast.success("Succeed to collect fee!");
            else
                toast.warn("Failed to collect fee!");
            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        }
        else if (notifyStatus.tag === "ADD_LOG") {
            setLogs([
                ...logs,
                notifyStatus.log,
            ]);
            setNotifyStatus({ success: true, tag: "NONE" });
        }
    }, [notifyStatus, logs]);

    useEffect(() => {
        if (connected) {
            console.log("Getting token accounts...");
            getTokenListByOwner(connection, publicKey, true).then(response => {
                setAssets(response);
                console.log("Success");
            });
        }
        else
            setAssets([]);
    }, [connected, connection, publicKey]);

    return (
        <AppContext.Provider
            value={{
                SERVER_URL,
                setLoadingPrompt,
                setOpenLoading,
                logout,
                user,
                setUser,
                users,
                setUsers,
                projects,
                setProjects,
                currentProject,
                setCurrentProject,
                currentVolume,
                setCurrentVolume,
                currentHolder,
                setCurrentHolder,
                currentPairkey,
                setCurrentPairkey,
                assets,
                setAssets,
                logs,
                setLogs,
                webSocket,
                setWebSocket,
                openWebSocket,
                closeWebSocket,
                extraWallets,
                setExtraWallets,
                emails,
                setEmails,
                jitoSigners,
                setJitoSigners,
                loadAllProjects,
                loadVolume,
                loadHolder,
                loadPairkey,
                loadAllUsers,
                loadAllEmails,
                loadAllJitoSigners,
                updateProject,
                walletBalanceData,
                setWalletBalanceData,
                volumeWalletBalanceData,
                setVolumeWalletBalanceData,
                holderWalletBalanceData,
                setHolderWalletBalanceData,
                teamWalletBalanceData,
                setTeamWalletBalanceData,
                updateAllBalances,
                updateVolumeBalances,
                updateHolderBalances,
                notifyStatus,
                setNotifyStatus,
            }}>
            <LoadingDialog isOpen={openLoading} prompt={loadingPrompt} />
            {
                user._id ?
                    (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%'
                        }}>
                            <NavBar breadCrumb={breadCrumb} />
                            {/* <SideBar className="2xl:block bg-[#222] w-[70px] 2xl:w-[190px] h-[100vh] border-r border-gray-highlight" /> */}

                            <div style={{
                                background: 'rgba(255,255,255,0.1)',
                                margin: '50px',
                                padding: '20px',
                                border: '1px solid white',
                                borderRadius: '16px',
                                userSelect: 'none'
                            }}>
                                {/* <NavBar className="flex w-full h-[70px] mt-2 bg-blue" breadCrumb={breadCrumb} /> */}

                                <Routes>
                                    <Route path="/dashboard" element={<DashboardPage />} />
                                    <Route path="/myprojects" element={<MyProjects />} />

                                    <Route path="/token-menu" element={<TokenMenuPage />} />

                                    <Route path="/key-pair" element={<KeyPairPage />} />
                                    <Route path="/create-token" element={<CreateTokenPage />} />

                                    <Route path="/set-authority" element={<SetAuthorityPage />} />
                                    <Route path="/openbook" element={<OpenBookMarketPage />} />
                                    <Route path="/manage-lp" element={<ManageLpPage />} />
                                    <Route path="/token-account" element={<TokenAccountPage />} />
                                    <Route path="/buy" element={<BuyPage />} />
                                    <Route path="/sell" element={<SellPage />} />
                                    <Route path="/transfer" element={<TransferPage />} />
                                    <Route path="/market-maker" element={<MarketMaker />} />
                                    <Route path="/volume-bot" element={<VolumeBot />} />
                                    <Route path="/holder-bot" element={<HolderBot />} />
                                    <Route path="/buysell-bot" element={<BuySellBot />} />
                                    {user.role === "admin" && <Route path="/log" element={<LogPage />} />}
                                </Routes>
                            </div>
                        </div>
                    ) :
                    (
                        <Routes>
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/" element={<LoginPage />} />
                        </Routes>
                    )
            }
        </AppContext.Provider>
    );
}

export default App;
