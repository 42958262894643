import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "../Base/Modal";

export default function SelectPumpWalletDialog({ isOpen, onOK, onCancel, tokenAccounts }) {

    const [tokenAccountIndex, setTokenAccountIndex] = useState(0);

    const handleOK = () => {
        onOK(tokenAccounts[tokenAccountIndex]);
        onCancel();
    }

    const handleCancel = () => {
        onCancel();
    }

    return (
        <Modal isOpen={isOpen} onClose={handleCancel}>
            <div className="flex flex-col p-8 rounded-[20px] font-sans justify-center bg-gray-dark">
                <div className="flex items-center justify-center w-full h-auto px-5 py-3 rounded-t-md revert-dialog ">
                    <div className="font-sans text-white uppercase text-2xl">
                         Token Accounts
                    </div>
                </div>
                <div className="justify-center m-4 p-4 h-[500px] overflow-y-auto">
                    {tokenAccounts.map((item, index) => {
                        return (
                            <div className={`flex flex-row font-mono gap-4 cursor-pointer p-2 ${index == tokenAccountIndex ? 'bg-blue-700' : 'bg-gray'} hover:brightness-50 hover:text-blue-500`} key={item.publicKey}
                                onClick = {() => {
                                    setTokenAccountIndex(index);
                                    toast.success("Token account selected");
                                }}>
                                <div className="text-white font-mono">{index + 2}</div>
                                <div className="text-white font-mono">{item.publicKey}</div>
                            </div>
                        );
                    })}
                </div>
                <div className="items-center w-full h-auto px-5 py-5 md:py-0 rounded-b-md">
                    <div className="flex items-center justify-center gap-5 my-5 w-full m-auto">
                        <button
                            className="pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-[rgba(255,0,0,0.5)] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white text-2xl uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                            onClick={handleOK}>
                            Confirm
                        </button>
                        <button
                            className="pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-green-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-2xl uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                            onClick={handleCancel}>
                            Cancel
                        </button>

                    </div>

                </div>
            </div>
        </Modal >
    );
}
