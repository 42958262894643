/********************************************************************** The Road to Valhalla! ************************************************************************
 *                                                                                                                                                                   *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌           *
 *  📌                                                                                                                                                  📌         *
 *  📌                 SOLANA LAUNCHPAD WITH JITO BUNDLING = INITIAL BUY FOR THE CLIENTS WITH SIMULATE, DISPERSE, ENABLE & BUY OPERATIONS               📌        *
 *  📌                                                                                                                                                  📌      *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌      *
 *                                                                                                                                                             *
 *  Project Type  : Solana Launchpad with Jito Bundling                                                                                                       *
 *   Project ID   : 2024-3                                                                                                                                   *
 *   Client Info  : Private                                                                                                                                 *
 *    Developer   : GloryDream, Rothschild, HappySmile (Nicknames)                                                                                         *
 *   Source Mode  : 100% Private                                                                                                                          *
 *   Description  : Solana Launchpad-Frontend .                                                                                                          *
 *  Writing Style : P0413-K0408-K1206                                                                                                                   *
 *                                                                                                                                                     *
 ********************************************************************** The Road to Valhalla! *********************************************************
 */
// import Samples node_modules
import { useContext, useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

// import personal layouts and styles

import MySidebar1 from "./MySidebar1";
import MySidebar2 from "./MySidebar2";

export default function TokenMneu({ className }) {

    const navigate = useNavigate();

    return (
        <div className="flex flex-row">
            <MySidebar1 />
            <div className="flex flex-col w-full h-full mb-auto ml-auto mr-auto m-16 rounded-[24px]" >
                <p className="text-3xl text-center  text-white p-4"> Create Token</p>
                <div className="flex flex-row border border-gray-border market-maker-panel m-4 rounded-[24px] cursor-pointer"
                    onClick={() => {
                        navigate('/key-pair');
                    }}>
                    <div className="flex flex-col justify-center items-center m-8 min-w-[200px]" >
                        <img src="/assets/projects/market_maker/tokenmenu_keypair.jpg" className="btn_bot" />
                        <h1 style={{ color: 'white', fontSize: '24px', marginTop: '16px' }}>KeyPair</h1>
                    </div>
                    <p className="rounded-[20px] panel-text text-xl m-8 p-4 text-justify">
                        &nbsp; Key Pairs are characterized by their already made shape with "pump-raydium onboard" and are used mainly in high-security locks for roof.
                        If we add the pump name to to the real SPL token we can attract more and more users to our liquidity.
                    </p>
                </div>

                <div className="flex flex-row border border-gray-border market-maker-panel m-4 rounded-[24px] cursor-pointer"
                    onClick={() => {
                        navigate('/create-token');
                    }}>
                    <div className="flex flex-col justify-center items-center m-8 min-w-[200px]" >
                        <img src="/assets/projects/market_maker/tokenmenu_spltoken.jpg" className="btn_bot" />
                        <h1 style={{ color: 'white', fontSize: '24px', marginTop: '16px' }}>SPL Token</h1>
                    </div>
                    <p className="rounded-[20px] panel-text text-xl m-8 p-4 text-justify">
                        &nbsp;
                        SPL is the token standard for Solana network tokens, analogous to ERC-20 tokens on the Ethereum network. 
                        You can launch your own tokens by creating new SPL tokens and also you are able to manage autority operations and other whole management.
                    </p>
                </div>

            </div>
            <MySidebar2 />
        </div>
    );
}
