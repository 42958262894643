/********************************************************************** The Road to Valhalla! ************************************************************************
 *                                                                                                                                                                   *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌           *
 *  📌                                                                                                                                                  📌         *
 *  📌                 SOLANA LAUNCHPAD WITH JITO BUNDLING = INITIAL BUY FOR THE CLIENTS WITH SIMULATE, DISPERSE, ENABLE & BUY OPERATIONS               📌        *
 *  📌                                                                                                                                                  📌      *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌      *
 *                                                                                                                                                             *
 *  Project Type  : Solana Launchpad with Jito Bundling                                                                                                       *
 *   Project ID   : 2024-3                                                                                                                                   *
 *   Client Info  : Private                                                                                                                                 *
 *    Developer   : GloryDream, Rothschild, HappySmile (Nicknames)                                                                                         *
 *   Source Mode  : 100% Private                                                                                                                          *
 *   Description  : Solana Launchpad-Frontend .                                                                                                          *
 *  Writing Style : P0413-K0408-K1206                                                                                                                   *
 *                                                                                                                                                     *
 ********************************************************************** The Road to Valhalla! *********************************************************
 */
// import Samples node_modules
import { IoIosAdd, IoIosAddCircle, IoIosRefresh } from "react-icons/io";
import { FaRegCopy, FaWallet, FaTrash, FaCheck, FaEye } from "react-icons/fa";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

// import personal layouts and styles
import AddExtraWalletDialog from "../components/Dialogs/AddExtraWalletDialog";
import AddEmailDialog from "../components/Dialogs/AddEmailDialog";
import AddJitoSignerDialog from "../components/Dialogs/AddJitoSignerDialog";
import NewProjectDialog from "../components/Dialogs/NewProjectDialog";
import ConfirmDialog from "../components/Dialogs/ConfirmDialog";
import { ellipsisAddress, isValidAddress } from "../utils/methods";
import { AppContext } from "../App";

// Global variables
const data_sidebar = [
    { label : "Create Token", _link: "/token-menu", img : "/assets/dashboard/sidebar_createToken.jpg" },
    { label : "Set Authority", _link: "/set-authority", img : "/assets/dashboard/sidebar_setAuthority.jpg" },
    { label : "OpenBookMarket", _link: "/openbook", img : "/assets/dashboard/sidebar_openbookmarket.jpg" },
    { label : "Manage LP", _link: "/manage-lp", img : "/assets/dashboard/sidebar_manageLP.jpg" },
    { label : "Token Account", _link: "/token-account", img : "/assets/dashboard/sidebar_tokenAccount.jpg"},
];

export default function MySidebar2({ className, img }) {
    const navigate = useNavigate();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '22.5%', marginTop : '1rem'}}>
            {data_sidebar.map((item) => {
                return <div style={{ padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={item.img}
                        className="btn_dashboard"
                        style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                            borderRadius: '20px',
                            border: '3px solid gray',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            navigate(item._link);
                        }}
                    ></img>
                    <h1 style={{ color: 'white', fontSize: '24px' , marginTop : '10px' }}>{item.label}</h1>
                </div>
            })}
        </div>
    );
}
