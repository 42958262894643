/********************************************************************** The Road to Valhalla! ************************************************************************
 *                                                                                                                                                                   *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌           *
 *  📌                                                                                                                                                  📌         *
 *  📌                 SOLANA LAUNCHPAD WITH JITO BUNDLING = INITIAL BUY FOR THE CLIENTS WITH SIMULATE, DISPERSE, ENABLE & BUY OPERATIONS               📌        *
 *  📌                                                                                                                                                  📌      *
 *  📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌📌      *
 *                                                                                                                                                             *
 *  Project Type  : Solana Launchpad with Jito Bundling                                                                                                       *
 *   Project ID   : 2024-3                                                                                                                                   *
 *   Client Info  : Private                                                                                                                                 *
 *    Developer   : GloryDream, Rothschild, HappySmile (Nicknames)                                                                                         *
 *   Source Mode  : 100% Private                                                                                                                          *
 *   Description  : Solana Launchpad-Frontend .                                                                                                          *
 *  Writing Style : P0413-K0408-K1206                                                                                                                   *
 *                                                                                                                                                     *
 ********************************************************************** The Road to Valhalla! *********************************************************
 */

// import Samples node_modules
import { FaDatabase, FaEllipsisV, FaExclamationTriangle, FaQuestion, FaRegCopy, FaSave } from "react-icons/fa";
import { IoIosAddCircle, IoIosDownload } from "react-icons/io";
import { useContext, useState, useEffect } from "react";
import { Popover } from "@headlessui/react";
import { toast } from "react-toastify";
import BigNumber from "bignumber.js";
import axios from "axios";
import bs58 from "bs58";

// import  blockchain integration
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { ellipsisAddress, isValidAddress } from "../utils/methods";
import { getMint, getAccount, getAssociatedTokenAddress } from "@solana/spl-token";
import { Keypair, PublicKey } from "@solana/web3.js";

// import personal layouts and styles

import { AppContext } from "../App";

import VolumeAmountDialog from "../components/Dialogs/VolumeAmountDialog";
import NewWalletDialog from "../components/Dialogs/NewWalletDialog";
import ZombieDialog from "../components/Dialogs/ZombieDialog";

import MySidebar1 from "./MySidebar1";
import MySidebar2 from "./MySidebar2";

export default function VolumeBot({ className }) {
    const {
        SERVER_URL,
        setLoadingPrompt,
        setOpenLoading,
        user,
        currentVolume,
        setCurrentVolume,
        volumeWalletBalanceData,
        updateVolumeBalances,
        notifyStatus,
        setNotifyStatus,
    } = useContext(AppContext);
    const { connected, publicKey } = useWallet();
    const { connection } = useConnection();

    const [copied, setCopied] = useState({});
    const [zombieDialog, setZombieDialog] = useState(false);
    const [newWalletDialog, setNewWalletDialog] = useState(false);
    const [volumeAmountDialog, setVolumeAmountDialog] = useState(false);
    const [targetWallet, setTargetWallet] = useState("");

    const [token, setToken] = useState("");
    const [tokenInfo, setTokenInfo] = useState({ decimals: "", totalSupply: "" });
    const [zombieWallet, setZombieWallet] = useState({ address: "", privateKey: "" });
    const [volumeAmount, setVolumeAmount] = useState("");
    const [walletAllChecked, setWalletAllChecked] = useState(false);
    const [walletChecked, setWalletChecked] = useState([]);
    const [walletSolBalance, setWalletSolBalance] = useState([]);
    const [walletTokenBalance, setWalletTokenBalance] = useState([]);
    const [walletSolAmount, setWalletSolAmount] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [isBotStarted, setIsBotStarted] = useState(false);
    const disabled = !user._id || user.role === "admin";

    const [solToDisperse, setSolToDisperse] = useState(0);
    // Object.keys(currentVolume).length === 0 ||

    useEffect(() => {
        if (currentVolume.token || currentVolume.zombie) {
            setToken(currentVolume.token.address);
            setZombieWallet({
                address: currentVolume.zombie,
                privateKey: "",
            });
        }
        else {
            setToken("");
            setZombieWallet({ address: "", privateKey: "" });
            setWalletAllChecked(false);
            setWalletChecked([]);
        }
    }, [currentVolume.token, currentVolume.zombie]);

    useEffect(() => {
        const getTokenInfo = async (tokenAddress, connection) => {
            try {
                const mint = new PublicKey(tokenAddress);
                const mintInfo = await getMint(connection, mint);
                setTokenInfo({
                    decimals: mintInfo.decimals.toString(),
                    totalSupply: new BigNumber(mintInfo.supply.toString() + "e-" + mintInfo.decimals.toString()).toFixed(0)
                });
            }
            catch (err) {
                console.log(err);
                setTokenInfo({
                    decimals: "",
                    totalSupply: "",
                });
            }
        };

        if (connected && isValidAddress(token)) {
            getTokenInfo(token, connection);
        }
        else {
            setTokenInfo({
                decimals: "",
                totalSupply: "",
            });
        }
    }, [connected, connection, token]);

    useEffect(() => {
        const updateBalance = async (connection, tokenAddress, owner) => {
            console.log("Updating balance...", tokenAddress, owner.toBase58());
            try {
                const mint = new PublicKey(tokenAddress);
                const mintInfo = await getMint(connection, mint);
                const tokenATA = await getAssociatedTokenAddress(mint, owner);
                const tokenAccountInfo = await getAccount(connection, tokenATA);
                const balance = Number(new BigNumber(tokenAccountInfo.amount.toString() + "e-" + mintInfo.decimals.toString()).toString()).toFixed(4);
                return balance;
            }
            catch (err) {
                console.log(err);
                return "0";
            }
        };

        if (connected && isValidAddress(token)) {
            updateBalance(connection, token, publicKey).then(response => {
                setVolumeAmount(response);
            });
        }
        else
            setVolumeAmount("");
    }, [connected, connection, token, publicKey]);

    useEffect(() => {
        if (currentVolume.wallets) {
            if (currentVolume.wallets.length !== walletChecked.length) {
                const newWalletChecked = currentVolume.wallets.map(() => false);
                setWalletChecked(newWalletChecked);
                setWalletAllChecked(false);
            }


            setWalletSolBalance(currentVolume.wallets.map(() => "-"));
            setWalletTokenBalance(currentVolume.wallets.map(() => "0"));
            setWalletSolAmount(currentVolume.wallets.map(item => item.initialSolAmount));
        }
        else {
            setWalletSolBalance([]);
            setWalletTokenBalance([]);
            setWalletSolAmount([]);
        }
    }, [currentVolume.wallets, walletChecked.length]);

    useEffect(() => {
        if (currentVolume.token && volumeWalletBalanceData.address === currentVolume.token.address && volumeWalletBalanceData.token.length === walletTokenBalance.length) {
            setWalletTokenBalance(volumeWalletBalanceData.token);
        }
    }, [currentVolume.token, volumeWalletBalanceData.address, volumeWalletBalanceData.token, walletTokenBalance.length]);

    useEffect(() => {
        if (currentVolume.token && volumeWalletBalanceData.address === currentVolume.token.address && volumeWalletBalanceData.sol.length === walletSolBalance.length) {
            setWalletSolBalance(volumeWalletBalanceData.sol);
        }
    }, [currentVolume.token, volumeWalletBalanceData.address, volumeWalletBalanceData.sol, walletSolBalance.length]);

    useEffect(() => {
        if (notifyStatus.tag === "VOLUME_BOT") {
            if (notifyStatus.success) {
                toast.success("Done Operations.");
                const wallets = currentVolume.wallets.map(item => item.address);
                updateVolumeBalances(connection, token, wallets);
                // handleStopBot();
            }
            else
                toast.warn("Failed to Run Volume Bot!");

            if (notifyStatus.volume) {
                if (currentVolume._id === notifyStatus.volume._id)
                    setCurrentVolume(notifyStatus.volume);
            }

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        } else if (notifyStatus.tag === "VOLUME_BOT_STOPPED") {
            if (notifyStatus.success) {
                toast.success("Bot Stopped.");
                const wallets = currentVolume.wallets.map(item => item.address);
                updateVolumeBalances(connection, token, wallets);
            }
            else
                toast.warn("Failed to Run Volume Bot!");

            if (notifyStatus.volume) {
                if (currentVolume._id === notifyStatus.volume._id)
                    setCurrentVolume(notifyStatus.volume);
            }

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        } else if (notifyStatus.tag === "DISPERSE_VOLUME_COMPLETED") {
            if (notifyStatus.success) {
                toast.success("Succeed to disperse!");

                const wallets = currentVolume.wallets.map(item => item.address);
                updateVolumeBalances(connection, token, wallets);
            }
            else
                toast.warn("Failed to disperse SOL!");

            if (notifyStatus.volume) {
                if (currentVolume._id === notifyStatus.volume._id)
                    setCurrentVolume(notifyStatus.volume);
            }

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        } else if (notifyStatus.tag === "BUY_VOLUME_COMPLETED") {
            if (notifyStatus.success)
                toast.success("Succeed to buy volume wallets!");
            else
                toast.warn("Failed to buy volume wallets!");

            if (notifyStatus.volume) {
                if (currentVolume._id === notifyStatus.volume._id)
                    setCurrentVolume(notifyStatus.volume);
            }

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        } else if (notifyStatus.tag === "SELL_VOLUME_COMPLETED") {
            if (notifyStatus.success) {
                const wallets = currentVolume.wallets.map(item => item.address);
                updateVolumeBalances(connection, token, wallets);

                toast.success("Succeed to sell volume wallets!");
            }

            else
                toast.warn("Failed to sell volume wallets!");

            if (notifyStatus.volume) {
                if (currentVolume._id === notifyStatus.volume._id)
                    setCurrentVolume(notifyStatus.volume);
            }

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        } 

        else if (notifyStatus.tag === "COLLECT_ALL_SOL") {
            if (notifyStatus.success) {
                toast.success("Succeed to collect all SOL!");
                const wallets = currentVolume.wallets.map(item => item.address);
                updateVolumeBalances(connection, token, wallets);
            }
            else
                toast.warn("Failed to collect all SOL!");

            if (notifyStatus.volume) {
                if (currentVolume._id === notifyStatus.volume._id)
                    setCurrentVolume(notifyStatus.volume);
            }

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        }
    }, [notifyStatus, currentVolume._id]);

    const copyToClipboard = async (key, text) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(text);
            toast.success("Copied");
            setCopied({
                ...copied,
                [key]: true,
            });
            setTimeout(() => setCopied({
                ...copied,
                [key]: false,
            }), 2000);
        }
        else
            console.error('Clipboard not supported');
    };

    const handleMouseDown = (e, id) => {
        e.preventDefault();
        setIsDragging(true);
        handleWalletChanged(id, "checked", !walletChecked[id])
    };

    const handleMouseEnter = (id) => {
        if (isDragging) {
            handleWalletChanged(id, "checked", !walletChecked[id])
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleWalletChanged = (index, key, value) => {
        console.log("Wallet changed:", index, key, value);
        if (key === "checked") {
            let newWalletChecked = [...walletChecked];
            newWalletChecked[index] = !newWalletChecked[index];
            setWalletChecked(newWalletChecked);

            let newWalletAllChecked = true;
            for (let i = 0; i < newWalletChecked.length; i++)
                newWalletAllChecked &&= newWalletChecked[i];
            setWalletAllChecked(newWalletAllChecked);

            let sum = 0;
            for (let j = 0; j < newWalletChecked.length; j++) {
                if (newWalletChecked[j]) {
                    sum += Number(walletSolAmount[j]);
                }
            }
            setSolToDisperse(sum.toFixed(3));
        }
        else if (key === "sol_amount") {
            let newWalletSOLAmount = [...walletSolAmount];
            newWalletSOLAmount[index] = value;
            setWalletSolAmount(newWalletSOLAmount);
        }
    };

    const handleCollectAllSol = async () => {
        if (!currentVolume.token)
            return;

        if (!isValidAddress(targetWallet)) {
            toast.warn("Please input wallet to send SOL!");
            return;
        }

        const validWalletChecked = walletChecked.filter(item => item === true);
        if (validWalletChecked.length === 0) {
            toast.warn("Please check wallets to collect SOL from!");
            return;
        }

        setLoadingPrompt("Collecting all SOL...");
        setOpenLoading(true);
        try {
            let wallets = [];
            for (let i = 0; i < currentVolume.wallets.length; i++) {
                if (walletChecked[i]) {
                    wallets = [
                        ...wallets,
                        currentVolume.wallets[i].address,
                    ];
                }
            }

            await axios.post(`${SERVER_URL}/api/v1/volume/collect-all-sol`,
                {
                    volumeId: currentVolume._id,
                    targetWallet,
                    wallets
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to collect all SOL!");
            setOpenLoading(false);
        }
    };

    const updateSolToDisperse = (checkWallets = walletChecked) => {
        let sum = 0;
        for (let j = 0; j < walletSolAmount.length; j++) {
            if (checkWallets[j])
                sum += Number(walletSolAmount[j]);
        }
        setSolToDisperse(sum.toFixed(3));
    }

    const handleWalletAllChecked = (e) => {
        console.log("Wallet all checked:", e.target.value, walletAllChecked);
        const newWalletAllChecked = !walletAllChecked;
        setWalletAllChecked(newWalletAllChecked);
        setWalletChecked(walletChecked.map(() => newWalletAllChecked));

        updateSolToDisperse(walletChecked.map(() => newWalletAllChecked));
    };

    const handleSaveVolume = async (_zombie = { address: "", privateKey: "" }) => {
        setLoadingPrompt("Saving volume...");
        setOpenLoading(true);
        const wallets = currentVolume.wallets.map((item, index) => {
            return {
                address: item.address,
                initialSolAmount: walletSolAmount[index],
                enabled: walletChecked[index]
            };
        });

        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/volume/save`,
                {
                    volumeId: currentVolume._id,
                    token: token,
                    zombie: (_zombie.address === "" ? zombieWallet : _zombie),
                    wallets: wallets
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );

            if (data.success) {
                if (currentVolume._id === data.volume._id)
                    setCurrentVolume(data.volume);
                toast.success("Volume has been saved successfully");
            }
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to save volume!");
        }
        setOpenLoading(false);
    };

    const handleOKZombiePrivateKey = async (key) => {
        try {
            const keypair = Keypair.fromSecretKey(bs58.decode(key));
            setZombieWallet({ address: keypair.publicKey.toBase58(), privateKey: key });
            await handleSaveVolume({ address: keypair.publicKey.toBase58(), privateKey: key });
        }
        catch (err) {
            console.log(err);
            toast.warn("Invalid private key!");
        }

        setZombieDialog(false);
    };

    const handleOKNewWallets = async (walletCount, fresh) => {
        console.log("New wallets:", walletCount, fresh);
        let count = 0;
        try {
            count = parseInt(walletCount);
        }
        catch (err) {
            console.log(err);
        }

        if (isNaN(count) || count < 0 || count > 90) {
            toast.warn("Invalid wallet count, wallet count must be in the range 1-90");
            return;
        }

        // if (walletCount < 1 || walletCount > 2) {
        //     toast.warn("You can only create 1 or 2 volume wallets");
        //     return;
        // }

        setNewWalletDialog(false);
        setLoadingPrompt("Generating new wallets...");
        setOpenLoading(true);
        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/volume/generate-wallets`,
                {
                    volumeId: currentVolume._id,
                    count: walletCount,
                    fresh: fresh,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            const newCurrentVolume = {
                ...currentVolume,
                wallets: data.volume.wallets,
            };
            setCurrentVolume(newCurrentVolume);
            toast.success("New wallets has been generated successfully");
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to generate new wallets!");
        }
        setOpenLoading(false);
    };

    const handleDownloadWallets = async () => {
        if (!currentVolume.token) {
            toast.warn("Select the volume");
            return;
        }

        setLoadingPrompt("Downloading wallets...");
        setOpenLoading(true);
        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/volume/download-wallets`,
                {
                    volumeId: currentVolume._id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );

            const downloadFile = (data, fileName) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            };

            downloadFile(data, `wallets_volume.csv`);
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to download wallets!");
        }
        setOpenLoading(false);
    };

    const handleOKMinMaxVolumeAmounts = (minAmount, maxAmount) => {
        function getRandomNumber(min, max) {
            const ret = Math.random() * (max - min) + min;
            return ret.toFixed(2);
        }

        try {
            let minX = -1;
            let maxX = -1;
            if (minAmount.charAt(minAmount.length - 1) === '%') {
                minX = Number(minAmount.slice(0, minAmount.length - 1));
                minX = Number(tokenInfo.totalSupply) * minX / 100;
            }
            else
                minX = Number(minAmount);



            if (isNaN(minX) || minX <= 0) {
                toast.warn("Invalid minimum amount");
                return;
            }

            if (maxAmount.charAt(maxAmount.length - 1) === '%') {
                maxX = Number(maxAmount.slice(0, maxAmount.length - 1));
                maxX = Number(tokenInfo.totalSupply) * maxX / 100;
            }
            else
                maxX = Number(maxAmount);

            if (isNaN(maxX) || maxX <= 0) {
                toast.warn("Invalid maximum amount");
                return;
            }

            if (minX > maxX) {
                const t = minX;
                minX = maxX;
                maxX = t;
            }

            console.log("Min:", minX, "Max:", maxX);


            let sum = 0;
            let newWalletSolAmount = [...walletSolAmount];
            for (let i = 0; i < newWalletSolAmount.length; i++) {
                if (walletChecked[i]) {
                    newWalletSolAmount[i] = getRandomNumber(minX, maxX);
                    sum += Number(newWalletSolAmount[i]);
                }
            }
            setSolToDisperse(sum.toFixed(3));
            setWalletSolAmount(newWalletSolAmount);
        }
        catch (err) {
            console.log(err);
            toast.warn("Invalid minimum/maximum amount");
        }

        setVolumeAmountDialog(false);
    };

    const handleSetVolumeAmounts = () => {
        const selectedWallets = walletChecked.filter((item) => item === true);
        if (selectedWallets.length === 0) {
            toast.warn("Please select wallets to set token amount");
            return;
        }
        setVolumeAmountDialog(true);
    };


    const handleStartBot = async () => {
        if (!currentVolume.token)
            return;

        if (!connected) {
            toast.warn("Connect your Wallet !");
            return;
        }

        if (!isValidAddress(token)) {
            toast.warn("Invalid token address!");
            return;
        }

        if (!isValidAddress(zombieWallet.address)) {
            toast.warn("Invalid zombie wallet!");
            return;
        }

        const validWalletChecked = walletChecked.filter(item => item === true);
        if (validWalletChecked.length === 0) {
            toast.warn("Please check wallets to buy tokens");
            return;
        }

        if (zombieWallet.address == "") {
            toast.warn("Set the Zombie Wallet");
            return;
        }

        if (walletSolBalance.length < 5) {
            toast.warn("You have to generate more than 5 wallets");
            return;
        }


        let wallets = [];
        for (let i = 0; i < currentVolume.wallets.length; i++) {
            if (!walletChecked[i])
                continue;

            const initialSolAmount = Number(walletSolAmount[i].toString().replaceAll(",", ""));
            if (isNaN(initialSolAmount) || initialSolAmount < 0) {
                toast.warn(`Wallet #${i + 1}: Invalid additional SOL amount`);
                return;
            }

            if (Number(walletSolBalance[i]) < 0.01) {
                toast.warn("Insufficient sol on some checked wallets.");
                return;
            }

            wallets = [
                ...wallets,
                {
                    address: currentVolume.wallets[i].address,
                    initialSolAmount: initialSolAmount,
                    enabled: currentVolume.wallets[i].enabled
                }
            ];
        }

        if (wallets.length % 5 != 0) {
            toast.warn("Wallet count must be the multiple of 5 for best performance!");
            return;
        }
        
        setIsBotStarted(2);
        try {
            setLoadingPrompt("Start Buying & Selling...");
            setOpenLoading(true);

            await axios.post(`${SERVER_URL}/api/v1/volume/start_bot`,
                {
                    volumeId: currentVolume._id,
                    wallets: wallets,
                    zombie: zombieWallet
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            setIsBotStarted(0);
            console.log("Bot configuration warning !");
            setOpenLoading(false);
        }
    };

    const handleBuy = async () => {
        if (!currentVolume.token)
            return;

        if (!connected) {
            toast.warn("Connect your Wallet !");
            return;
        }

        if (!isValidAddress(token)) {
            toast.warn("Invalid token address!");
            return;
        }

        if (!isValidAddress(zombieWallet.address)) {
            toast.warn("Invalid zombie wallet!");
            return;
        }

        const validWalletChecked = walletChecked.filter(item => item === true);
        if (validWalletChecked.length === 0) {
            toast.warn("Please check wallets to buy tokens");
            return;
        }

        let wallets = [];
        for (let i = 0; i < currentVolume.wallets.length; i++) {
            if (!walletChecked[i])
                continue;

            const initialSolAmount = Number(walletSolAmount[i].toString().replaceAll(",", ""));
            if (isNaN(initialSolAmount) || initialSolAmount < 0) {
                toast.warn(`Wallet #${i + 1}: Invalid additional SOL amount`);
                return;
            }

            wallets = [
                ...wallets,
                {
                    address: currentVolume.wallets[i].address,
                    initialSolAmount: initialSolAmount,
                    enabled: currentVolume.wallets[i].enabled
                }
            ];
        }


        try {
            setLoadingPrompt("Start Buying...");
            setOpenLoading(true);

            await axios.post(`${SERVER_URL}/api/v1/volume/buy`,
                {
                    volumeId: currentVolume._id,
                    wallets,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to disperse!");
            setOpenLoading(false);
        }
    };

    const handleSell = async () => {
        if (!currentVolume.token)
            return;

        if (!connected) {
            toast.warn("Connect your Wallet !");
            return;
        }

        if (!isValidAddress(token)) {
            toast.warn("Invalid token address!");
            return;
        }

        if (!isValidAddress(zombieWallet.address)) {
            toast.warn("Invalid zombie wallet!");
            return;
        }

        const validWalletChecked = walletChecked.filter(item => item === true);
        if (validWalletChecked.length === 0) {
            toast.warn("Please check wallets to buy tokens");
            return;
        }

        let wallets = [];
        for (let i = 0; i < currentVolume.wallets.length; i++) {
            if (!walletChecked[i])
                continue;

            const initialSolAmount = Number(walletSolAmount[i].toString().replaceAll(",", ""));
            if (isNaN(initialSolAmount) || initialSolAmount < 0) {
                toast.warn(`Wallet #${i + 1}: Invalid additional SOL amount`);
                return;
            }

            wallets = [
                ...wallets,
                {
                    address: currentVolume.wallets[i].address,
                    initialSolAmount: initialSolAmount,
                    enabled: currentVolume.wallets[i].enabled
                }
            ];
        }

        try {
            setLoadingPrompt("Start Buying...");
            setOpenLoading(true);

            await axios.post(`${SERVER_URL}/api/v1/volume/sell`,
                {
                    volumeId: currentVolume._id,
                    wallets,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to disperse!");
            setOpenLoading(false);
        }


    };

    const handleStopBot = async () => {
        try {
            setIsBotStarted(1);

            axios.post(`${SERVER_URL}/api/v1/volume/stop_bot`,
                {
                    status : 'stop'
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {

            console.log(err);
        }
    };

    const handleDisperseSOL = async () => {
        if (!currentVolume.token)
            return;

        if (!connected) {
            toast.warn("Connect your Wallet !");
            return;
        }

        if (!isValidAddress(token)) {
            toast.warn("Invalid token address!");
            return;
        }

        if (!isValidAddress(zombieWallet.address)) {
            toast.warn("Invalid zombie wallet!");
            return;
        }

        const validWalletChecked = walletChecked.filter(item => item === true);
        if (validWalletChecked.length === 0) {
            toast.warn("Please check wallets to buy tokens");
            return;
        }

        let wallets = [];
        for (let i = 0; i < currentVolume.wallets.length; i++) {
            if (!walletChecked[i])
                continue;

            const initialSolAmount = Number(walletSolAmount[i].toString().replaceAll(",", ""));
            if (isNaN(initialSolAmount) || initialSolAmount < 0) {
                toast.warn(`Wallet #${i + 1}: Invalid additional SOL amount`);
                return;
            }

            wallets = [
                ...wallets,
                {
                    address: currentVolume.wallets[i].address,
                    initialSolAmount: initialSolAmount,
                    enabled: currentVolume.wallets[i].enabled
                }
            ];
        }

        try {
            setLoadingPrompt("Dispersing SOL...");
            setOpenLoading(true);

            await axios.post(`${SERVER_URL}/api/v1/volume/disperse`,
                {
                    volumeId: currentVolume._id,
                    wallets,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to disperse!");
            setOpenLoading(false);
        }
    };

    return (
        <div className="flex flex-row">
            <MySidebar1 />
            <div className={`${className} w-full m-8 justify-between flex flex-col text-white rounded-[4px] border border-gray-highlight p-4 pb-3 w-full`}>
                <ZombieDialog isOpen={zombieDialog} onOK={handleOKZombiePrivateKey} onCancel={() => setZombieDialog(false)} />
                <NewWalletDialog isOpen={newWalletDialog} onOK={handleOKNewWallets} onCancel={() => setNewWalletDialog(false)} />
                <VolumeAmountDialog isOpen={volumeAmountDialog} onOK={handleOKMinMaxVolumeAmounts} onCancel={() => setVolumeAmountDialog(false)} />
                <div className="flex flex-col">
                    <div className="flex items-start justify-between w-full h-auto">
                        <div className="flex items-center font-sans text-xs font-medium text-white">
                            <div className="font-bold uppercase text-3xl">Volume Bot </div>
                            {currentVolume?.token?.address &&
                                <>
                                    <div className="mx-2 text-gray-normal opacity-30">/</div>
                                    <div className="font-semibold text-gray-normal">{ellipsisAddress(currentVolume?.token?.address)}</div>
                                    {copied["token_address"] ?
                                        (<svg xmlns="http://www.w3.org/2000/svg" className="w-3.5 h-3.5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                        </svg>) :
                                        <FaRegCopy className="w-3.5 h-3.5 ml-2 transition ease-in-out transform cursor-pointer active:scale-95 duration-90" onClick={() => copyToClipboard("token_address", currentVolume?.token?.address)} />}
                                    <a href={`https://solscan.io/account/${currentVolume?.token?.address}`} target="_blank" rel="noreferrer">
                                        <img className="w-3.5 h-3.5 object-contain ml-2" src="/assets/solscan.png" alt="solscan" />
                                    </a>
                                    <a href={`https://www.dextools.io/app/en/solana/pair-explorer/${currentVolume?.token?.address}`} target="_blank" rel="noreferrer">
                                        <img className="w-3.5 h-3.5 object-contain ml-2" src="/assets/dextool.png" alt="dextools" />
                                    </a>
                                    <a href={`https://dexscreener.com/solana/${currentVolume?.token?.address}`} target="_blank" rel="noreferrer">
                                        <img className="w-3.5 h-3.5 object-contain ml-2" src="/assets/dexscreener.png" alt="dexscreener" />
                                    </a>
                                </>
                            }
                        </div>
                        <div className="flex">
                            <button
                                className={`rounded-sm cursor-pointer w-9 h-9 bg-green-normal ${disabled ? "!bg-gray-highlight text-gray-normal" : "active:scale-95 transition duration-90 ease-in-out transform "}`}
                                disabled={disabled}
                                onClick={
                                    () => {
                                        handleSaveVolume(zombieWallet);
                                    }
                                }>
                                <FaSave className="w-4 h-4 m-auto" />
                            </button>
                        </div>
                    </div>
                    <div className="w-full mt-[6px] grid grid-cols-12 gap-3">
                        <div className="col-span-12 md:col-span-6 2xl:col-span-3">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Token Address<span className="pl-1 text-yellow-normal">*</span>
                            </div>
                            <input
                                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                placeholder="Enter Address"
                                disabled={disabled}
                                value={token}
                                onChange={(e) => setToken(e.target.value)}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 2xl:col-span-3">
                            <Popover className="relative flex items-center font-sans text-xs uppercase text-gray-normal">
                                <div className="whitespace-nowrap">Zombie Wallet<span className="pl-1 text-yellow-normal">*</span></div>
                                <Popover.Button className="border border-red-normal text-[6px] flex items-center justify-center cursor-pointer rounded-full w-3 h-3 ml-1">
                                    <FaQuestion className="text-green-normal" />
                                </Popover.Button>
                                <Popover.Panel className="absolute z-10 px-2 py-1 text-xs text-center text-white normal-case border rounded-sm bg-gray-highlight bottom-5 border-red-normal">
                                    This wallet distributes SOL to all wallets.
                                </Popover.Panel>
                            </Popover>
                            <div className={`flex items-center justify-between outline-none border border-gray-border text-gray-normal font-sans text-sm pl-2.5 bg-transparent w-full h-button mt-1 pr-1 ${disabled && "text-gray-border border-gray-highlight"}`}>
                                <div className={`w-full pr-1 truncate ${zombieWallet.address && "text-white"}`}>
                                    {
                                        zombieWallet.address ?
                                            ellipsisAddress(zombieWallet.address) :
                                            "NOT SET"
                                    }
                                </div>
                                <div className="flex items-center text-base">
                                    {zombieWallet.address && !copied["zombie_wallet_0"] &&
                                        <FaRegCopy className="w-4 cursor-pointer text-gray-normal hover:text-green-normal" onClick={() => copyToClipboard("zombie_wallet_0", zombieWallet.address)} />
                                    }
                                    {zombieWallet.address && copied["zombie_wallet_0"] &&
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                        </svg>
                                    }
                                    {!disabled && <FaEllipsisV className="w-4 ml-1 cursor-pointer text-gray-normal hover:text-green-normal" onClick={() => setZombieDialog(true)} />}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 2xl:col-span-3">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Generating wallets
                            </div>

                            <div className={`flex items-center justify-between outline-none border border-gray-border text-gray-normal font-sans text-sm pl-2.5 bg-transparent w-full h-button mt-1 pr-1 ${disabled && "text-gray-border border-gray-highlight"}`}>
                                <button
                                    className="w-full h-button rounded-[4px] justify-left items-center gap-1 inline-flex active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap items-center "
                                    disabled={disabled}
                                    onClick={() => setNewWalletDialog(true)}
                                >
                                    <IoIosAddCircle className="text-lg text-green-normal mr-1" />
                                    Generate Wallets
                                </button>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 2xl:col-span-3">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Downloading Private Keys
                            </div>
                            <div className={`flex items-center justify-left outline-none border border-gray-border text-gray-normal font-sans text-sm pl-2.5 bg-transparent w-full h-button mt-1 pr-1 ${disabled && "text-gray-border border-gray-highlight"}`}>
                                <button
                                    className="pl-3 pr-4 h-button rounded-[4px] justify-center items-center gap-1 inline-flex active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap items-center"
                                    disabled={disabled}
                                    onClick={handleDownloadWallets}
                                >
                                    <IoIosDownload className="text-lg text-green-normal mr-1" />
                                    Download Wallets
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between w-full gap-2 mt-3 mb-3 font-sans">
                        <div className="flex items-center gap-3 font-sans text-sm text-gray-normal">
                            <div>
                                Selected: <span className="text-white">{walletChecked.filter(wal => wal).length}</span>
                            </div>
                            <div>
                                SOL to Disperse: <span className="text-white">{solToDisperse}</span>
                            </div>
                        </div>
                        <div className="flex flex-col justify-end gap-2 lg:items-center lg:flex-row">
                            <button
                                className="pl-3 pr-4 h-button rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                                disabled={disabled}
                                onClick={handleSetVolumeAmounts}
                            >
                                <img className="w-4 h-4 text-green-normal" src="/assets/sol.svg" alt="sol" />
                                Set S.amount
                            </button>
                        </div>
                    </div>
                    <div className="w-full overflow-visible font-sans">
                        <div className="flex flex-col w-full h-full text-white bg-transparent bg-clip-border">
                            <div className="relative border border-gray-highlight">
                                <div className="h-[calc(100vh-435px)] 2xl:h-[calc(100vh-365px)] overflow-y-auto">
                                    {(!currentVolume.wallets || currentVolume.wallets.length === 0) &&
                                        <div className="absolute flex items-center justify-center gap-2 my-3 text-base font-bold text-center uppercase -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-gray-border">
                                            <FaExclamationTriangle className="text-sm opacity-50 text-green-normal" /> No Wallet
                                        </div>
                                    }
                                    <table className="min-w-[700px] w-full text-xs">
                                        <thead className=" text-gray-normal">
                                            <tr className="uppercase h-7 bg-[#262626] sticky top-0 z-10">
                                                <th className="w-8 text-center">
                                                    <div className="flex items-center justify-center">
                                                        <input type="checkbox"
                                                            className="w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0"
                                                            checked={walletAllChecked}
                                                            onChange={handleWalletAllChecked} />
                                                    </div>
                                                </th>
                                                <th className="w-8">
                                                    <p className="leading-none text-center">
                                                        #
                                                    </p>
                                                </th>
                                                <th className="">
                                                    <p className="leading-none text-center">
                                                        Address
                                                    </p>
                                                </th>
                                                <th className="">
                                                    <p className="leading-none text-left">
                                                        SOL Balance
                                                    </p>
                                                </th>
                                                <th className="">
                                                    <p className="leading-none text-left">
                                                        Token Balance
                                                    </p>
                                                </th>
                                                <th className="w-[15%]">
                                                    <p className="leading-none text-center">
                                                        Disperse SOL
                                                    </p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-xs text-white" onMouseLeave={handleMouseUp}>
                                            {
                                                currentVolume.wallets &&
                                                currentVolume.wallets.map((item, index) => {
                                                    return (
                                                        <tr key={index}
                                                            className={`${index % 2 === 1 && "bg-[#ffffff02]"} hover:bg-[#ffffff08] ${walletChecked[index] && "!bg-[#00000030]"} h-8`}
                                                        >
                                                            <td className="text-center"
                                                                onMouseDown={(e) => handleMouseDown(e, index)}
                                                                onMouseEnter={() => handleMouseEnter(index)}
                                                                onMouseUp={handleMouseUp}
                                                            >
                                                                <div className="flex items-center justify-center">
                                                                    <input type="checkbox"
                                                                        className="w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0"
                                                                        checked={walletChecked[index]}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="">
                                                                <p className="leading-none text-center text-gray-normal">
                                                                    {index + 1}
                                                                </p>
                                                            </td>
                                                            <td className="">
                                                                <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal text-gray-normal">
                                                                    <p className="bg-transparent border-none outline-none">
                                                                        {ellipsisAddress(item.address, 12)}
                                                                    </p>
                                                                    {
                                                                        copied["wallet_" + index] ?
                                                                            (<svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                                                            </svg>) :
                                                                            (<FaRegCopy className="w-3 h-3 transition ease-in-out transform cursor-pointer active:scale-95 duration-90" onClick={() => copyToClipboard("wallet_" + index, item.address)} />)
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="">
                                                                <p className="flex items-center justify-start text-yellow-normal">
                                                                    <img className="w-3 mr-1" src="/assets/solsemi.svg" alt="sol" />
                                                                    {walletSolBalance[index]}
                                                                </p>
                                                            </td>

                                                            <td className="">
                                                                <p className="flex items-center justify-start text-white">
                                                                    <FaDatabase className="mr-1 opacity-50 text-xxs text-gray-normal" />
                                                                    <span>
                                                                        {
                                                                            walletTokenBalance[index] ?
                                                                                Number(walletTokenBalance[index]?.split(".")[0] ?? "0").toLocaleString()
                                                                                : "0"
                                                                        }
                                                                    </span>
                                                                    <span className="font-normal text-gray-normal">.{
                                                                        walletTokenBalance[index] ?
                                                                            walletTokenBalance[index]?.split(".")[1]
                                                                            : "0000"
                                                                    }
                                                                    </span>
                                                                </p>
                                                            </td>
                                                            <td className="text-center">
                                                                <input
                                                                    className="outline-none border border-gray-highlight font-medium text-gray-normal placeholder:text-gray-border text-xs px-2.5 bg-transparent text-center w-[100px] h-[26px]"
                                                                    disabled={disabled}
                                                                    value={walletSolAmount[index]}
                                                                    onChange={(e) => handleWalletChanged(index, "sol_amount", e.target.value)} />
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative flex items-center justify-between h-full gap-3 mt-3 text-white bg-transparent bg-clip-border">
                        <div className="flex items-center grow">
                            <div className="font-sans text-xs uppercase text-gray-normal whitespace-nowrap">
                                Target Wallet:
                            </div>
                            <input
                                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button ml-2 grow max-w-[430px]"
                                placeholder="Target Wallet Address"
                                value={targetWallet}
                                onChange={(e) => setTargetWallet(e.target.value)}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <button
                                className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 bg-green-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed"
                                disabled={disabled}
                                onClick={handleCollectAllSol}>
                                Collect All SOL
                            </button>

                            <div className="w-[1px] h-6 border-r border-gray-normal opacity-40 mx-1"></div>
                            <button
                                className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 bg-green-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed"
                                disabled={disabled}
                                onClick={handleDisperseSOL}>
                                Disperse SOL
                            </button>
                            {/* <button
                                className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 bg-green-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed"
                                disabled={disabled}
                                onClick={handleBuy}>
                                Buy
                            </button>
                            <button
                                className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 bg-green-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed"
                                disabled={disabled}
                                onClick={handleSell}>
                                Sell
                            </button> */}
                            <button
                                className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 bg-green-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed"
                                disabled={disabled}
                                onClick={handleStartBot}>
                                Start BOT
                            </button>
                            <button
                                className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 bg-red-700 active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed"
                                disabled={isBotStarted == 0}
                                style={{ zIndex: 4000 }}
                                onClick={handleStopBot}>
                                Stop BOT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <MySidebar2></MySidebar2>
        </div>
    );
}